import React from "react";
import { GeneralContext, GeneralProvider } from "../../../shared/general";
import { AuthContext, AuthProvider } from "./auth";
import { MarketPlaceContext, MarketPlaceProvider } from "./marketplace";
import { ServicesContext, ServicesProvider } from "./services";
import { WeatherProvider, WeatherContext } from "./weather";

const ClientGlobalProvider = ({ children }) => {
  return (
    <AuthProvider>
      <GeneralProvider>
        <MarketPlaceProvider>
          <ServicesProvider>
            <WeatherProvider>{children}</WeatherProvider>
          </ServicesProvider>
        </MarketPlaceProvider>
      </GeneralProvider>
    </AuthProvider>
  );
};

export { AuthContext, MarketPlaceContext, ServicesContext, GeneralContext, WeatherContext };

export default ClientGlobalProvider;
