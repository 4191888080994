import { useContext, useState, useEffect } from "react";
import { GET_LOCATIONS_FOR_WEATHER_ALERT, WEATHER_ALERT_STATUS } from "../../gql";
import { AuthContext, WeatherContext } from "../../store";
import { useLazyQuery } from "@apollo/client";
import { useAllObjectUserIdsQuery } from "../../widgets/allObjectUserIdQuery";

export function WeatherAlertStatus({ children }) {
  const { user } = useContext(AuthContext);
  const { setAlertStatus } = useContext(WeatherContext);
  const [weatherAlert, setWeatherAlert] = useState(false);

  const { objects: locations } = useAllObjectUserIdsQuery({
    entity: "locations",
    query: GET_LOCATIONS_FOR_WEATHER_ALERT,
    user_id: user?.id,
    order_by: { name: "asc" },
  });

  const [weatherAlertStatus, { loading, error, data, refetch }] = useLazyQuery(WEATHER_ALERT_STATUS);

  useEffect(() => {
    if ((!loading, !error, data)) {
      setWeatherAlert(data?.weather_alert_status?.status);
    }
  }, [loading, error, data]);

  useEffect(() => {
    async function getWeatherAlert() {
      if (locations.length > 0 && !data) {
        const weatherAlertQueryObject = await locations.map((location) => ({
          lat: location.lat,
          lng: location.lng,
        }));
        weatherAlertStatus({ variables: { locations: weatherAlertQueryObject }, pollInterval: 20 * 60 * 1000 });
      }
    }
    getWeatherAlert();
    if (locations.length > 0 && data) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    setAlertStatus(weatherAlert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherAlert]);

  return children;
}
