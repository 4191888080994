import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Sugar } from 'react-preloaders';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { usePosition } from 'use-position';
import { APP_CONFIG } from '../../shared/app_config';
import { ScrollIntoView } from '../../shared/components';
import { getLocationData, useLocalStorage } from '../../shared/helpers';
import { ProtectedRoute, WeatherAlertStatus } from './components';
import { PATHS } from './config';
import {
  GET_GENERAL_CERTIFICATIONS,
  GET_GENERAL_COUNTRIES,
  GET_GENERAL_LABELS,
  GET_GENERAL_LANGUAGES,
  GET_GENERAL_LOGISTICS,
  GET_GENERAL_PRODUCTS,
  GET_GENERAL_PRODUCT_TYPES,
  GET_GENERAL_SERVICES,
  GET_GENERAL_SERVICE_TYPES,
  GET_GENERAL_SETTINGS,
  GET_GENERAL_TRANSLATIONS,
  GET_USER_BY_ID,
} from './gql';
import { AuthContext, GeneralContext } from './store';
import { getBrowserLanguage } from '../../shared/helpers';
import { Redirect } from 'react-router-dom';

// LAZY LOAD PAGES

// Misc
const Homepage = lazy(() => import('./pages/misc/homepage'));
const AboutUs = lazy(() => import('./pages/misc/about-us'));
// const Careers = lazy(() => import('./pages/misc/careers'));
const Careers = lazy(() => import('./pages/misc/Careers/CareersLatest'));
const ViewJob = lazy(() => import('./pages/misc/Careers/ViewJob'));

const TermsOfService = lazy(() => import('./pages/misc/termsofservice'));
const FAQ = lazy(() => import('./pages/misc/faq'));
const ContactUs = lazy(() => import('./pages/misc/ContactUs'));
const Privacy = lazy(() => import('./pages/misc/privacy'));
const Form = lazy(() => import('./pages/misc/form'));
const Quotation = lazy(() => import('./pages/misc/quotation'));
const QuotationService = lazy(() => import('./pages/misc/quotationServices'));

const Ezra3 = lazy(() => import('./pages/misc/ezra3'));
const QrPage = lazy(() => import('./pages/misc/qr-page'));
const Disclaimer = lazy(() => import('./pages/misc/disclaimer'));
const HelpDesk = lazy(() => import('./pages/misc/help-desk'));
const Partners = lazy(() => import('./pages/misc/partners'));
const Posts = lazy(() => import('./pages/misc/posts'));
const Post = lazy(() => import('./pages/misc/post'));
const ForGrowersPage = lazy(() => import('./pages/misc/forgrowers'));
const InvestorsPage = lazy(() => import('./pages/misc/investors'));
const ForServiceProviderPage = lazy(() =>
  import('./pages/misc/forserviceproviders')
);
// const Support = lazy(() => import("./pages/misc/support"));

// Marketplace
const MarketPlace = lazy(() => import('./pages/marketplace/marketplace'));
const NewTradeOffer = lazy(() => import('./pages/marketplace/new-offer'));
const EditTradeOffer = lazy(() => import('./pages/marketplace/edit-offer'));
const ViewTradeOffer = lazy(() => import('./pages/marketplace/view-offer'));
const TradeOffers = lazy(() => import('./pages/user/trade/offers/index'));

const NewTradeOrder = lazy(() => import('./pages/user/trade/orders/new-order'));
const TradeOrders = lazy(() => import('./pages/user/trade/orders/index'));
const ViewTradeOrder = lazy(() =>
  import('./pages/user/trade/orders/view-order')
);

// Auth
const Signin = lazy(() => import('./pages/auth/signin'));
const Register = lazy(() => import('./pages/auth/register'));
const AuthRedirect = lazy(() => import('./pages/auth/redirect'));
const Authorization = lazy(() => import('./pages/auth/authorization'));
const DefaultLocation = lazy(() => import('./pages/auth/default-location'));

// Services
const Services = lazy(() => import('./pages/services/services'));
const NewServiceOffer = lazy(() => import('./pages/services/new-offer'));
const EditServiceOffer = lazy(() => import('./pages/services/edit-offer'));
const ViewServiceOffer = lazy(() => import('./pages/services/view-offer'));
const ServiceOffers = lazy(() => import('./pages/user/service/offers/index'));
const NewServiceOrder = lazy(() =>
  import('./pages/user/service/orders/new-order')
);
const ViewServiceOrder = lazy(() =>
  import('./pages/user/service/orders/view-order')
);
const ServiceOrders = lazy(() => import('./pages/user/service/orders/index'));

// Checkout
const TradeCheckoutSuccess = lazy(() =>
  import('./pages/user/trade/checkout/success')
);
const CheckoutForm = lazy(() =>
  import('./pages/user/trade/checkout/checkout_form')
);

const TradeCheckoutCancelled = lazy(() =>
  import('./pages/user/trade/checkout/cancelled')
);
const ServiceCheckoutSuccess = lazy(() =>
  import('./pages/user/service/checkout/success')
);
const ServiceCheckoutCancelled = lazy(() =>
  import('./pages/user/service/checkout/cancelled')
);

// User
const Dashboard = lazy(() => import('./pages/user/dashboard'));
const Account = lazy(() => import('./pages/user/account'));
const LocationDetails = lazy(() => import('./pages/user/location-details'));
const Settings = lazy(() => import('./pages/user/settings'));
const Transactions = lazy(() => import('./pages/user/transactions'));

//pdf
const Pdf = lazy(() => import('./pages/pdf'));

const Socials = lazy(() => import('./pages/Socials/Socials'));

const createApolloClient = ({ authToken, role }) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-Hasura-Role': role || 'anonymous',
  };
  if (authToken) headers.Authorization = `Bearer ${authToken}`;

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      new WebSocketLink({
        uri: APP_CONFIG.REACT_APP_HASURA_URL_WS,
        options: {
          lazy: true,
          reconnect: true,
          connectionParams: async () => {
            return { headers };
          },
        },
      }),
      new HttpLink({
        uri: APP_CONFIG.REACT_APP_HASURA_URL,
        headers: headers,
      })
    ),
  });
};

const AppBase = () => {
  const [local_id] = useLocalStorage('id');
  const { latitude, longitude } = usePosition();
  const {
    setGeneralProducts,
    setGeneralProductTypes,
    setGeneralServices,
    setGeneralServiceTypes,
    setGeneralCertifications,
    setGeneralLanguages,
    setGeneralCountries,
    setGeneralSettings,
    setGeneralTranslations,
    setGeneralLocation,
    setGeneralLanguage,
    setGeneralLogistics,
    general_language,
    general_languages,
  } = useContext(GeneralContext);

  const [local_language] = useLocalStorage('language');
  const {
    authenticateUser,
    setUserData,
    setUserRole,
    is_authenticated,
    user_role,
  } = useContext(AuthContext);
  const { loading, error, data } = useSubscription(GET_USER_BY_ID, {
    variables: { id: local_id },
  });
  useEffect(() => {
    // if (local_language && local_language !== general_language) setGeneralLanguage(local_language);
    // if (data?.object?.language && data?.object?.language !== general_language) setGeneralLanguage(data?.object?.language);

    if (!local_language && general_languages.length > 0) {
      const browser_language = getBrowserLanguage(general_languages);
      setGeneralLanguage(browser_language);
    } else if (local_language === 'ar_eg') {
      const html = document.getElementsByTagName('html')[0];
      html.setAttribute('dir', 'rtl');
    }

    if (!loading && !error && data?.object && !is_authenticated) {
      authenticateUser(true);
      setUserData(data?.object);

      // // set user's role
      const { buyer_access, grower_access, provider_access } = data?.object;
      if (buyer_access && user_role !== 'buyer') setUserRole('buyer');
      else if (grower_access && user_role !== 'grower') setUserRole('grower');
      else if (provider_access && user_role !== 'provider')
        setUserRole('provider');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data, general_languages]);

  const { data: data_products } = useQuery(GET_GENERAL_PRODUCTS);
  const { data: data_services } = useQuery(GET_GENERAL_SERVICES);
  const { data: data_product_types } = useQuery(GET_GENERAL_PRODUCT_TYPES);
  const { data: data_service_types } = useQuery(GET_GENERAL_SERVICE_TYPES);
  const { data: data_certifications } = useQuery(GET_GENERAL_CERTIFICATIONS);
  const { data: data_labels } = useQuery(GET_GENERAL_LABELS);
  const { data: data_translations } = useQuery(GET_GENERAL_TRANSLATIONS, {
    variables: { language: general_language },
  });
  const { data: data_countries } = useQuery(GET_GENERAL_COUNTRIES);
  const { data: data_logistics } = useQuery(GET_GENERAL_LOGISTICS);
  const { data: data_languages } = useQuery(GET_GENERAL_LANGUAGES);
  const { data: data_settings } = useQuery(GET_GENERAL_SETTINGS);

  // SET STARTUP DATA
  useEffect(() => {
    if (
      data_translations?.translations?.length > 0 &&
      data_labels?.labels?.length > 0
    )
      setGeneralTranslations(
        data_translations?.translations,
        data_labels?.labels,
        general_language
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_labels, data_translations]);
  useEffect(() => {
    if (data_products?.products?.length > 0)
      setGeneralProducts(data_products?.products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_products]);
  useEffect(() => {
    if (data_product_types?.product_types?.length > 0)
      setGeneralProductTypes(
        data_product_types?.product_types.map((e) => ({
          ...e,
          product_name: e.product.name,
        }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_product_types]);
  useEffect(() => {
    if (data_services?.services?.length > 0)
      setGeneralServices(data_services?.services);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_services]);
  useEffect(() => {
    if (data_service_types?.service_types?.length > 0)
      setGeneralServiceTypes(
        data_service_types?.service_types.map((e) => ({
          ...e,
          service_name: e.service.name,
        }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_service_types]);
  useEffect(() => {
    if (data_certifications?.certifications?.length > 0)
      setGeneralCertifications(data_certifications?.certifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_certifications]);
  useEffect(() => {
    if (data_languages?.enum_languages?.length > 0)
      setGeneralLanguages(data_languages?.enum_languages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_languages]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_countries]);
  useEffect(() => {
    if (data_settings?.settings?.length > 0)
      setGeneralSettings(data_settings?.settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_settings]);
  useEffect(() => {
    if (data_logistics?.enum_logistics?.length > 0)
      setGeneralLogistics(data_logistics?.enum_logistics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_logistics]);

  // SET STARTUP DATA
  useEffect(() => {
    if (data_countries?.enum_countries?.length > 0) {
      setGeneralCountries(data_countries?.enum_countries);
      if (latitude && longitude) {
        const getLocData = async () => {
          setGeneralLocation(
            await getLocationData(
              latitude,
              longitude,
              data_countries?.enum_countries
            )
          );
        };
        getLocData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_countries, latitude, longitude]);

  return (
    <Router basename={'/'}>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.pathname.split('/')[1] || '/'}
              timeout={{ enter: 300, exit: 150 }}
              classNames="fade"
              appear
            >
              <WeatherAlertStatus>
                <ScrollIntoView>
                  <Suspense
                    fallback={<Sugar color={'#ccd92b'} customLoading={true} />}
                  >
                    <div className="container-fluid overflow-hidden">
                      <Switch location={location}>
                        {/* MISC */}
                        <Route exact path={PATHS.base} component={Homepage} />
                        <Route exact path={PATHS.about} component={AboutUs} />
                        {/* <Route exact path={PATHS.careers} component={Careers} /> */}
                        <Route exact path={PATHS.careers} component={Careers} />
                        <Route exact path={PATHS.viewJob} component={ViewJob} />

                        <Route
                          exact
                          path={PATHS.terms}
                          component={TermsOfService}
                        />
                        <Route exact path={PATHS.faq} component={FAQ} />
                        <Route
                          exact
                          path={PATHS.contact}
                          component={ContactUs}
                        />
                        <Route exact path={PATHS.form} component={Form} />
                        <Route
                          exact
                          path={PATHS.quotation}
                          component={Quotation}
                        />
                        <Route
                          exact
                          path={PATHS.quotationIdMarketplace}
                          component={Quotation}
                        />
                        <Route
                          exact
                          path={PATHS.quotationIdService}
                          component={QuotationService}
                        />

                        <Route exact path={PATHS.ezra3} component={Ezra3} />
                        <Route exact path={PATHS.privacy} component={Privacy} />
                        <Route
                          exact
                          path={PATHS.disclaimer}
                          component={Disclaimer}
                        />
                        <Route
                          exact
                          path={PATHS.helpdesk}
                          component={HelpDesk}
                        />
                        <Route
                          exact
                          path={PATHS.partners}
                          component={Partners}
                        />
                        <Route exact path={PATHS.posts} component={Posts} />
                        <Route
                          exact
                          path={`${PATHS.post}/:uid`}
                          component={Post}
                        />
                        <Route
                          exact
                          path={PATHS.forgrowers}
                          component={ForGrowersPage}
                        />
                        <Route
                          exact
                          path={PATHS.investors}
                          component={InvestorsPage}
                        />
                        <Route
                          exact
                          path={PATHS.forserviceproviders}
                          component={ForServiceProviderPage}
                        />
                        {/* <Route exact path={PATHS.support} component={Support} /> */}
                        <Route
                          exact
                          path={`${PATHS.qr}/:fileId`}
                          component={QrPage}
                        />

                        {/* AUTH */}
                        <Route exact path={PATHS.signin} component={Signin} />
                        <Route
                          exact
                          path={PATHS.register}
                          component={Register}
                        />
                        <Route
                          exact
                          path={PATHS.confirmAuth}
                          component={AuthRedirect}
                        />
                        <Route
                          path={PATHS.authorize}
                          component={Authorization}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.defaultFarmSetup}
                          render={(props) => (
                            <DefaultLocation {...props} role="grower" />
                          )}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.defaultDeliverySetup}
                          render={(props) => (
                            <DefaultLocation {...props} role="buyer" />
                          )}
                        />

                        {/* USER */}
                        <ProtectedRoute
                          exact
                          path={PATHS.dashboard}
                          component={Dashboard}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.locations}/:locationId`}
                          component={LocationDetails}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.account}
                          component={Account}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.account}/:userId`}
                          component={Account}
                        />
                        <ProtectedRoute
                          exact={false}
                          path={PATHS.settings}
                          component={Settings}
                        />
                        {user_role === 'buyer' || user_role === 'grower' ? (
                          <ProtectedRoute
                            exact={false}
                            path={PATHS.transactions}
                            component={Transactions}
                          />
                        ) : null}

                        {/* MARKETPLACE */}
                        <Route
                          exact
                          path={PATHS.marketplace}
                          component={MarketPlace}
                        />

                        {/* <Route
                        exact
                        path={`${PATHS.trade_view_offer}/:offerId`}
                        component={ViewTradeOffer}
                      /> */}

                        <ProtectedRoute
                          exact
                          path={PATHS.trade_new_offer}
                          component={NewTradeOffer}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.trade_edit_offer}/:offerId`}
                          component={EditTradeOffer}
                        />
                        {/* <ProtectedRoute
                        exact
                        path={`${PATHS.trade_view_offer}/:offerId`}
                        component={ViewTradeOffer}
                      /> */}
                        <Route
                          exact
                          path={`${PATHS.trade_view_offer}/:offerId`}
                          component={ViewTradeOffer}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.trade_offers}
                          component={TradeOffers}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.trade_offers}/:tabId`}
                          component={TradeOffers}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.trade_new_order}/:offerId`}
                          component={NewTradeOrder}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.trade_view_order}/:orderId`}
                          component={ViewTradeOrder}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.trade_orders}
                          component={TradeOrders}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.trade_orders}/:tabId`}
                          component={TradeOrders}
                        />

                        {/* SERVICES */}
                        <Route
                          exact
                          path={PATHS.services}
                          component={Services}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.service_new_offer}
                          component={NewServiceOffer}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.service_edit_offer}/:offerId`}
                          component={EditServiceOffer}
                        />
                        {/* <ProtectedRoute
                        exact
                        path={`${PATHS.service_view_offer}/:offerId`}
                        component={ViewServiceOffer}
                      /> */}
                        <Route
                          exact
                          path={`${PATHS.service_view_offer}/:offerId`}
                          component={ViewServiceOffer}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.service_offers}
                          component={ServiceOffers}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.service_offers}/:tabId`}
                          component={ServiceOffers}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.service_new_order}/:offerId`}
                          component={NewServiceOrder}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.service_view_order}/:orderId`}
                          component={ViewServiceOrder}
                        />
                        <ProtectedRoute
                          exact
                          path={PATHS.service_orders}
                          component={ServiceOrders}
                        />
                        <ProtectedRoute
                          exact
                          path={`${PATHS.service_orders}/:tabId`}
                          component={ServiceOrders}
                        />

                        {/* CHECKOUT */}
                        {/* <Route
											exact
											path={`${PATHS.trade_checkout_success}/:orderId/:paymentId/:sessionId/:advance`}
											component={TradeCheckoutSuccess}
										/> */}
                        <ProtectedRoute
                          path={`${PATHS.trade_checkout_success}`}
                          component={TradeCheckoutSuccess}
                        />
                        <Route
                          exact
                          path={`${PATHS.checkout_form}/`}
                          component={CheckoutForm}
                        />
                        <Route
                          exact
                          path={`${PATHS.trade_checkout_cancelled}/:orderId/:paymentId/:sessionId/:advance`}
                          component={TradeCheckoutCancelled}
                        />
                        <Route
                          exact
                          path={`${PATHS.service_checkout_success}/:orderId/:paymentId/:sessionId/:advance`}
                          component={ServiceCheckoutSuccess}
                        />
                        <Route
                          exact
                          path={`${PATHS.service_checkout_cancelled}/:orderId/:paymentId/:sessionId/:advance`}
                          component={ServiceCheckoutCancelled}
                        />

                        {/* pdf */}
                        <Route exact path={PATHS.pdf} component={Pdf} />
                        <Route
                          exact
                          path={PATHS.followUs}
                          component={Socials}
                        />
                        <Route
                          exact
                          path={PATHS.social}
                          render={
                            () => <Redirect to={PATHS.followUs} />
                          }
                        />
                      </Switch>
                    </div>
                  </Suspense>
                </ScrollIntoView>
              </WeatherAlertStatus>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
};

const ClientRoot = () => {
  const { id_token: authToken, user_role: role } = useContext(AuthContext);
  return (
    <>
      <ApolloProvider client={createApolloClient({ authToken, role })}>
        <AppBase />
      </ApolloProvider>
    </>
  );
};

export default ClientRoot;
