import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslatedLabel } from '../../../../shared/hooks/useTranslatedLabel';
import { PATHS } from '../../config';
import newLogo from '../../../assets/footer/logo.png';
import { Accordion, Button, Card } from 'react-bootstrap';
function generateLink() {
  let number = '+201111984555';
  let message = 'Mahaseel Masr';
  let url = 'https://wa.me/';
  return `${url}${number}?text=${message}`;
}

const Bottom = () => {
  const { t } = useTranslatedLabel();
  return (
    <>
      <div className="py-5 bottom d-sm-none d-lg-block ">
        <div className="container">
          <div className="row sm-px-3 md-px-5">
            <div className="col-xl-3 col-lg-3 col-12 sm-mb-5 md-mb-5">
              <div className="text-center bottom-logo">
                <img className="pb-3" src={newLogo} alt="Mahaseel logo" />
                <br />
                <span
                  style={{
                    fontSize: '11px',
                    fontWeight: 300,
                    marginLeft: '5px',
                  }}
                >
                  {t('poweredby')}
                </span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-6 ">
              <div className="bottom-widget" style={{ textAlign: 'start' }}>
                <h4 className="widget-title font-weight-bold">
                  {t('company')}
                </h4>
                <ul>
                  <li>
                    <Link to={PATHS.about}>{t('about')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.marketplace}> {t('marketplace')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.services}>{t('services')}</Link>{' '}
                  </li>
                  <li>
                    <Link to={PATHS.faq}>{t('faq')}</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-6">
              <div className="bottom-widget" style={{ textAlign: 'start' }}>
                <h4 className="widget-title font-weight-bold">{t('legal')}</h4>
                <ul>
                  <li>
                    <Link to={PATHS.disclaimer}>{t('disclaimer')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.privacy}>{t('privacy_policy')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.terms}>{t('terms_of_service')}</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-6 help-support">
              <div className="bottom-widget" style={{ textAlign: 'start' }}>
                <h4 className="widget-title font-weight-bold">
                  {t('support')}
                </h4>
                <ul>
                  <li>
                    <a
                      href={generateLink()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-whatsapp"></i>
                      <span>{t('whats')}</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:info@mahaseel.net?subject=subject&body=body"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      <span>{t('email')}</span>
                    </a>
                  </li>
                  <li>
                    <Link to={PATHS.contact}>
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <span>{t('contact')}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-6">
              <div className="bottom-widget" style={{ textAlign: 'start' }}>
                <h4 className="widget-title font-weight-bold">
                  {t('follow_us')}
                </h4>
                <div className="footer-social d-flex">
                  <ul className="col-6 px-0">
                    <li>
                      <a
                        href="https://www.facebook.com/mahaseelmasrB2B"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-facebook-official"
                          aria-hidden="true"
                        ></i>
                        <span>{t('facebook')}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/mahaseel_masr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                        <span>{t('twitter')}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/mahaseel-masr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-linkedin-square"
                          aria-hidden="true"
                        ></i>
                        <span>{t('linkedin')}</span>
                      </a>
                    </li>
                  </ul>
                  <ul className=" col-6 px-0">
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCyogXOVbIzM5N0QoX4jR5sQ"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-youtube-play"
                          aria-hidden="true"
                        ></i>
                        <span>{t('youtube')}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/mahaseel_masr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                        <span>{t('instagram')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom d-sm-block d-lg-none 
      "
      >
        {/* <div className="container"> */}
        <Accordion defaultActiveKey="0" className="py-4 ">
          <div>
            <div className="mb-5 ">
              <div className="text-center bottom-logo">
                <img className="pb-3" src={newLogo} alt="Mahaseel logo" />
              </div>
            </div>
            <div className="bottom-widget">
              <FooterAccordion title={t('company')}>
                <ul>
                  <li>
                    <Link to={PATHS.about}>{t('about')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.marketplace}>{t('marketplace')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.services}>{t('services')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.faq}>{t('faq')}</Link>
                  </li>
                </ul>
              </FooterAccordion>
            </div>
            <div className="bottom-widget">
              <FooterAccordion title={t('legal')}>
                <ul>
                  <li>
                    <Link to={PATHS.disclaimer}>{t('disclaimer')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.privacy}>{t('privacy_policy')}</Link>
                  </li>
                  <li>
                    <Link to={PATHS.terms}>{t('terms_of_service')}</Link>
                  </li>
                </ul>
              </FooterAccordion>
            </div>
            <div className="bottom-widget help-support">
              <FooterAccordion title={t('support')}>
                <ul>
                  <li>
                    <a
                      href={generateLink()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-whatsapp"></i>
                      <span>{t('whats')}</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:info@mahaseel.net?subject=subject&body=body"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      <span>{t('email')}</span>
                    </a>
                  </li>
                  <li>
                    <Link to={PATHS.contact}>
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <span>{t('contact')}</span>
                    </Link>
                  </li>
                </ul>
              </FooterAccordion>
            </div>
            <div
              className="mb-3"
              style={{
                height: '1px',
                width: '100%',
                backgroundColor: '#e0e0e0',
              }}
            ></div>
            <div className="bottom-widget" style={{ textAlign: 'center' }}>
              <div className="footer-social d-flex justify-content-center">
                <ul className="d-flex ">
                  <li>
                    <a
                      href="https://www.facebook.com/mahaseelmasrB2B"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-facebook-official"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/mahaseel_masr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/mahaseel-masr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-linkedin-square"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCyogXOVbIzM5N0QoX4jR5sQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/mahaseel_masr/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-instagram "
                        style={{ marginInlineEnd: 0 }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-center">
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: 300,
                }}
              >
                {t('poweredby')}
              </span>
            </div>
          </div>
        </Accordion>
        {/* </div> */}
      </div>
    </>
  );
};

const FooterAccordion = ({ title, children }) => {
  return (
    <Card className="mb-0">
      <Card.Header className="p-1">
        <Accordion.Toggle
          className="widget-title font-weight-bold w-100 d-flex justify-content-between"
          style={{ textAlign: 'start' }}
          as={Button}
          variant="div"
          eventKey={title}
        >
          {title}
          <i className="fa fa-sort-desc" aria-hidden="true"></i>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse style={{ padding: '0 12px' }} eventKey={title}>
        <Card.Body className="p-1 ">{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default Bottom;
