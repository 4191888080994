import React, { useState } from "react";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Modal, Button } from "react-bootstrap";

export function WeatherAlertWidget({ weatherAlerts }) {
  const [show, setShow] = useState(false);
  const handleModal = () => setShow(!show);
  return (
    <>
      <Button variant="link" className="text-info " onClick={handleModal}>
        <u>Show Info</u>
      </Button>
      <Modal show={show} size="lg" onHide={handleModal} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-info">Extreme weather alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {weatherAlerts.map((alert, idx) => (
            <AlertBody key={idx} alert={alert} />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Understood
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function AlertBody({ alert }) {
  const { t } = useTranslatedLabel();
  const [readMore, setreadMore] = useState(false);

  return (
    <div className="mb-3">
      <h4 className="my-1">{alert?.headline}</h4>
      <br />
      <strong>{t("event_type")}</strong>: {alert?.event}
      <br />
      <strong>{t("from")}</strong>: {Date(alert?.effective)}
      <br />
      <strong>{t("till")}</strong>: {Date(alert?.expires)}
      <br />
      <strong>{t("severity")}</strong>: {alert?.severity}
      <br />
      <strong>{t("note")}</strong>: {alert?.note}
      <br />
      <strong>{t("description")}</strong>:
      {readMore ? (
        alert?.desc
      ) : (
        <>
          {alert?.desc.substring(0, 130).concat("...")}
          <strong>
            <button className="btn btn-link text-info" onClick={() => setreadMore(!readMore)}>
              Read more.
            </button>
          </strong>
        </>
      )}
      <hr />
    </div>
  );
}
