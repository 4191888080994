import React from "react";
import { useSubscription } from "@apollo/client";

import { NotificationListItem } from "./UI";
import { S_NOTIFICATIONS } from "../gql";

export const Notifications = ({ recipient_id, ...rest }) => {
  const { data } = useSubscription(S_NOTIFICATIONS, {
    variables: { recipient_id },
  });

  return (
    <>
      <button className="btn btn-light px-1 py-0 notification-btn" style={{ fontSize: "1.1rem" }}>
        <i
          className="las la-bell"
          style={
            data && data.notifications.map((n) => n.active).includes(true)
              ? { "--new-msg": "#FD1D1D" }
              : { "--new-msg": "transparent" }
          }
        ></i>
        <div className="notification-dropdown-list">
          {data &&
            data.notifications.length > 0 &&
            data.notifications
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((item, index) => <NotificationListItem notification={item} key={index} />)}
          {data && data.notifications.length < 1 && <NotificationListItem is_default={true} />}
        </div>
      </button>
    </>
  );
};
