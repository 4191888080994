import axios from "axios";
import { APP_CONFIG } from "../../../shared/app_config";

export const createCheckoutSession = async (props) => {
	return axios({
		method: "POST",
		url: APP_CONFIG.REACT_APP_PAYMENT_GATEWAY_URL,
		headers: { "Content-Type": "application/json" },
		data: JSON.stringify(props),
	});
};

export const createKhalesSession = async (props) => {
	return axios({
		method: "POST",
		url: APP_CONFIG.REACT_APP_KHALES_GATEWAY_URL,
		headers: { "Content-Type": "application/json" },
		data: JSON.stringify(props),
	});
};

