import React from "react";
import { Rate } from "antd";
// import { HeartTwoTone } from "@ant-design/icons";
import { StarFilled } from "@ant-design/icons";
import { HeartOutlined } from "@ant-design/icons";
import { useTranslatedLabel } from "../../../../shared/hooks";

export function ItemRating({ editable = false, value = 0, setValue, size = "16px" }) {
  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <>
      {editable ? (
        <Rate allowHalf value={parseFloat(value)} style={{ fontSize: size }} allowClear={false} onChange={handleChange} />
      ) : (
        <Rate allowHalf disabled value={parseFloat(value)} style={{ fontSize: size }} />
      )}
    </>
  );
}

export function ItemRatingCount({ value, count }) {
  const { t } = useTranslatedLabel();

  return (
    <div className="d-flex align-items-center ">
      <StarFilled className="mr-2" />
      <span className="mr-2">{Math.round(value * 10) / 10 ?? 0}</span>
      <span>
        ({count ?? 0} {t("reviews")})
      </span>
    </div>
  );
}

export function UserRating({ editable = false, value = 0, setValue, size = "14px", color = "red" }) {
  const handleChange = (value) => {
    setValue(value);
  };
  return (
    <>
      {editable ? (
        <Rate
          allowHalf
          value={parseFloat(value)}
          character={<HeartOutlined />}
          style={{ fontSize: size, color: color }}
          allowClear={false}
          onChange={handleChange}
        />
      ) : (
        <Rate
          allowHalf
          disabled
          value={parseFloat(value)}
          character={<HeartOutlined />}
          style={{ fontSize: size, color: color }}
        />
      )}
    </>
  );
}
