import { APP_CONFIG } from "../../../shared/app_config";

const uploadImagesToCloudinary = (files) => {
	const formData = new FormData();
	for (const file of files) {
		formData.append("image", file);
	}
	return fetch(`${APP_CONFIG.REACT_APP_IMAGES_UPLOAD_URL}`, {
		method: "POST",
		body: formData,
	});
};

export { uploadImagesToCloudinary };
