export const transformAPIResultToMarketPlaceOfferObj = (props) => {
  return {
    ...props,
    product_id: props?.product_type?.product.id,
    product_type_id: props?.product_type?.id,
    product: props?.product_type?.product?.name,
    product_type: props?.product_type?.name,
    images: props?.pictures,
    // rating: props?.reviews_aggregate?.aggregate?.avg?.rating,
    // review_count: props?.reviews_aggregate?.aggregate?.count,
  };
};

export const transformAPIResultToServicesOfferObj = (props) => {
  return {
    ...props,
    service_id: props?.service_type?.service.id,
    service_type_id: props?.service_type?.id,
    service: props?.service_type?.service?.name,
    service_type: props?.service_type?.name,
    images: props?.pictures,
    // rating: props?.reviews_aggregate?.aggregate?.avg?.rating,
    // review_count: props?.reviews_aggregate?.aggregate?.count,
  };
};
