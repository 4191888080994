import axios from "axios";
import storage from "store2";
import { APP_CONFIG } from "../../../shared/app_config";

export const addNotification = async ({ recipient_id, header, message, type, cta_link, cta_title, user_role }) => {
  const addNotificationMutation = `
    mutation AddNotification(
			user_role: String
      $recipient_id: String
      $header: String
      $message: String
      $type: String
      $cta_link: String
      $cta_title: String
    ) {
      insert_notifications_one(
        object: {
					user_role: $user_role
          recipient_user_id: $recipient_id
          header: $header
          message: $message
          type: $type
          cta_link: $cta_link
          cta_title: $cta_title
        }
      ) {
        id
        user_id
				user_role
        recipient_user_id
        header
        message
        recipient_user {
          name
        }
      }
    }
  `;
  const graphqlReq = {
    query: addNotificationMutation,
    variables: {
      user_role,
      recipient_id,
      header,
      message,
      type,
      cta_link,
      cta_title,
    },
  };

  const token = storage.has("id_token") ? storage.get("id_token") : "";

  return axios({
    method: "POST",
    url: `${APP_CONFIG.REACT_APP_HASURA_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "X-Hasura-Role": user_role,
    },
    data: JSON.stringify(graphqlReq),
  });
};

export const newUserNotificationHelper = async ({ id }) => {
  const getUserQuery = `
    query GET_USER_DATA($id: String!) {
      users_by_pk(id: $id) {
        name
        grower_access
        buyer_access
        provider_access
      }
    }
  `;

  const graphqlReq = {
    query: getUserQuery,
    variables: {
      id,
    },
  };

  return axios({
    method: "POST",
    url: `${APP_CONFIG.REACT_APP_HASURA_URL}`,
    headers: {
      "Content-Type": "application/json",
      "X-Hasura-Role": "anonymuous",
    },
    data: JSON.stringify(graphqlReq),
  });
};
