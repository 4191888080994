import { gql } from '@apollo/client';

export const SEARCH_COUNT_USERID = (entity, field = 'name') =>
  gql`query ${entity}($text:String!, $user_id: String!) {counts: ${entity}_aggregate(where:{user_id:{_eq:$user_id}, ${field}:{_eq:$text}}) { aggregate {count} } }`;

export const SEARCH_COUNT = (entity, field = 'name') =>
  gql`query ${entity}($text:String!) {counts: ${entity}_aggregate(where:{${field}:{_eq:$text}}) { aggregate {count} } }`;

export const GET_GENERAL_TRANSLATIONS = gql`
  query translations($language: enum_languages_enum!) {
    translations(where: { language: { _eq: $language } }) {
      id
      map_id
      text
    }
  }
`;
export const GET_GENERAL_LABELS = gql`
  query labels {
    labels(order_by: { name: asc }) {
      id
      name
      desc
      icon
    }
  }
`;
export const GET_GENERAL_PRODUCTS = gql`
  query products {
    products(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      active
      id
      pictures
    }
  }
`;
export const GET_GENERAL_SERVICES = gql`
  query services {
    services(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      pictures
    }
  }
`;
export const GET_GENERAL_PRODUCT_TYPES = gql`
  query product_types {
    product_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      product_id
      pictures
      product {
        pictures
        id
        name
      }
    }
  }
`;
export const GET_GENERAL_SERVICE_TYPES = gql`
  query service_types {
    service_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      service_id
      pictures
      service {
        pictures
        id
        name
      }
    }
  }
`;
export const GET_GENERAL_CERTIFICATIONS = gql`
  query certifications {
    certifications(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
    }
  }
`;
export const GET_GENERAL_COUNTRIES = gql`
  query enum_countries {
    enum_countries(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_LOGISTICS = gql`
  query enum_logistics {
    enum_logistics(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_CURRENCIES = gql`
  query enum_currencies {
    enum_currencies(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_LANGUAGES = gql`
  query enum_languages {
    enum_languages(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;

export const GET_GENERAL_SETTINGS = gql`
  query settings {
    settings(order_by: { label: asc }) {
      id
      label
      is_numeric
      text
      numeric
    }
  }
`;

export const Q_DUMMY = gql`
  query DUMMY(
    $where: locations_bool_exp
    $filter: locations_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [locations_order_by!]
    $distinct_on: [locations_select_column!]
  ) {
    objects: locations(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
    }
    totals: locations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const Q_REQUESTS = gql`
  query GetOffers(
    $where: requests_bool_exp
    $filter: requests_bool_exp
    $limit: Int
    $offset: Int
    $from_date: order_by = desc_nulls_last
    $distinct_on: [requests_select_column!]
  ) {
    objects: requests(
      where: $where
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: { from_date: $from_date }
    ) {
      pictures
      id
      user {
        id
      }
      location {
        city
        country
      }
      number_of_units
      from_date
      to_date
      product_type {
        id
        name
        pictures
        product {
          id
          name
        }
      }
    }
    product_types: requests(where: $filter, distinct_on: product_type_id) {
      id: product_type_id
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
    }
    countries: requests(where: $filter, distinct_on: location_id) {
      id: location_id
      location {
        lat
        lng
        id
        uid
        created_at
        updated_at
        color
        active
        country
      }
    }
    users: requests(where: $filter, distinct_on: user_id) {
      id: user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
    }
    totals: requests_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const Q_PROVIDER_SERVICES = gql`
  query GetProviderOffers(
    $where: provider_services_bool_exp
    $filter: provider_services_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [provider_services_order_by!]
    $distinct_on: [provider_services_select_column!]
  ) {
    objects: provider_services(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
      uid
      status

      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      service_type {
        pictures
        pictures
        active
        id
        name
        service_id
        pictures
        service {
          pictures
          active
          id
          name
        }
      }
      price_per_unit
      range_in_kms
      pictures
    }
    service_types: provider_services(
      where: $filter
      distinct_on: service_type_id
    ) {
      id: service_type_id
      service_type {
        pictures
        pictures
        active
        id
        name
        service_id
        pictures
        service {
          pictures
          active
          id
          name
        }
      }
    }
    countries: provider_services(where: $filter, distinct_on: location_id) {
      id: location_id
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
    }
    totals: provider_services_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_OFFERS = gql`
  query GetOffers {
    requests(
      where: {
        _or: [
          { type: { _eq: buy_request }, active: { _eq: true } }
          { type: { _eq: sell_offer }, active: { _eq: true } }
        ]
      }
    ) {
      active
      id
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      unit

      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      from_date
      to_date
      pictures
      total_amount
      advance_amount
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      status

      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_OFFERS_BY_CATEGORY = gql`
  query GetOffersByCategory($product_id: uuid!) {
    requests(
      where: {
        active: { _eq: true }
        product_id: { _eq: $product_id }
        _or: [{ type: { _eq: buy_request } }, { type: { _eq: sell_offer } }]
      }
    ) {
      active
      id
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      unit

      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      from_date
      to_date
      pictures
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      status

      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_LOCATIONS_BY_USER_ID = gql`
  query GetLocationsByUserId($user_id: String!) {
    locations(where: { user_id: { _eq: $user_id } }) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      lat
      lng
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      country
      user_id
      user {
        active
        id
        email
        photo_url
      }
    }
  }
`;

export const GET_CERTIFICATIONS = gql`
  query get_certifications {
    certifications(order_by: { uid: asc }) {
      active
      name
      active
      id
      uid
      created_at
      updated_at
    }
  }
`;

export const GET_PRODUCTS = gql`
  query get_products {
    products(where: { active: { _eq: true } }, order_by: { name: asc }) {
      active
      pictures
      uid
      created_at
      updated_at
      name
      active
      id
      product_types_aggregate(
        where: { active: { _eq: true } }
        order_by: { name: asc }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_PRODUCT_TYPES = gql`
  query get_product_types($product_id: uuid!) {
    product_types(
      where: {
        _and: [{ product_id: { _eq: $product_id } }, { active: { _eq: true } }]
      }
      order_by: { name: asc }
    ) {
      active
      active
      id
      name
      uid
      created_at
      updated_at
      product_id
      pictures
    }
  }
`;

export const GET_SERVICE_CATEGORIES = gql`
  query get_services {
    services(where: { active: { _eq: true } }, order_by: { name: asc }) {
      active
      active
      id
      name
      uid
      created_at
      updated_at
      pictures
      service_types_aggregate(
        where: { active: { _eq: true } }
        order_by: { name: asc }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_SERVICE_SUBCATEGORIES = gql`
  query get_service_types($service_id: uuid!) {
    service_types(
      where: { service_id: { _eq: $service_id } }
      order_by: { name: asc }
    ) {
      active
      active
      id
      name
      uid
      created_at
      updated_at
      service_id
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GET_LOCATIONS(
    $where: locations_bool_exp!
    $order_by: [locations_order_by!]
  ) {
    objects: locations(where: $where, order_by: $order_by) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      country
      user_id
      user {
        active
        id
        email
        photo_url
      }
    }
  }
`;

export const GET_LOCATIONS_FOR_WEATHER_ALERT = gql`
  query GET_LOCATIONS_FOR_WEATHER_ALERT(
    $user_id: String!
    $order_by: [locations_order_by!]
  ) {
    objects: locations(
      where: { user_id: { _eq: $user_id }, active: { _eq: true } }
      order_by: $order_by
    ) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      country
      user_id
      user {
        active
        id
        email
        photo_url
      }
    }
  }
`;

export const GET_ALL_OFFERS = gql`
  query GET_ALL_OFFERS(
    $where: requests_bool_exp
    $order_by: [requests_order_by!]
  ) {
    objects: requests(where: $where, order_by: $order_by) {
      active
      id
      updated_at
      timestamp
      pictures
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      type
      days_to_maturity
      bid_by_admin
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }

      status

      advance_amount
      total_amount
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      unit

      total_amount
      from_date
      to_date
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics
      advance_payment_percentage
      active
      id
      uid
      created_at
      updated_at
      notes {
        created_at
        updated_at
        active
        id
        text
      }
      user_id
      user {
        active
        id
        email
        photo_url
        name
        photo_url
      }
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      trade_orders_aggregate {
        aggregate {
          count
        }
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_MATCHING_OFFERS = gql`
  query GET_MATCHING_OFFERS(
    $where: requests_bool_exp
    $order_by: [requests_order_by!]
  ) {
    objects: requests(where: $where, order_by: $order_by) {
      active
      id
      updated_at
      timestamp
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }

      status

      advance_amount
      total_amount
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      unit

      from_date
      to_date
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics
      advance_payment_percentage
      active
      id
      uid
      created_at
      updated_at
      notes {
        created_at
        updated_at
        active
        id
        text
      }
      user_id
      user {
        active
        id
        email
        photo_url
        name
        photo_url
      }
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
      children: matching_offers(order_by: $order_by) {
        active
        id
        updated_at
        timestamp
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        type
        days_to_maturity
        views: views_aggregate {
          aggregate {
            sum {
              searches
              views
            }
          }
        }

        status

        advance_amount
        total_amount
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        unit

        total_amount
        from_date
        to_date
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        logistics
        advance_payment_percentage
        active
        id
        uid
        created_at
        updated_at
        notes {
          created_at
          updated_at
          active
          id
          text
        }
        user_id
        user {
          active
          id
          email
          photo_url
          name
          photo_url
        }
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
    }
  }
`;

export const GET_REQUESTS = gql`
  query get_requests {
    requests {
      active
      id
      updated_at
      timestamp
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }

      status

      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      unit

      total_amount
      from_date
      to_date
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics
      advance_payment_percentage
      active
      id
      uid
      created_at
      updated_at
      notes {
        created_at
        updated_at
        active
        id
        text
      }
      user_id
      user {
        active
        id
        email
        photo_url
      }
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_SIMILAR_REQUEST = gql`
  query get_similar_requests($id: uuid!) {
    requests(where: { id: { _eq: $id } }) {
      active
      id
      similar_offers_by_type_user {
        uid
        created_at
        updated_at
        active
        id
        from_date
        user_id
        user {
          active
          id
          email
          photo_url
        }
        uid
        created_at
        updated_at
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        status

        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
    }
  }
`;

export const GET_USER_OFFERS = gql`
  query GET_USER_OFFERS(
    $where: requests_bool_exp
    $filter: requests_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [requests_order_by!]
    $distinct_on: [requests_select_column!]
    $user_id: String!
  ) {
    objects: requests(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active

      created_at
      id
      uid
      created_at
      updated_at
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      unit

      request_id
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      from_date
      to_date
      pictures
      total_amount
      advance_amount
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      status

      active_bids: requests_aggregate(
        where: { user_id: { _neq: $user_id }, active: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
      bids: requests_aggregate(where: { user_id: { _neq: $user_id } }) {
        aggregate {
          count
        }
      }
      request {
        uid
        created_at
        updated_at
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_TRADE_ORDERS = gql`
  query GET_TRADE_ORDERS(
    $where: trade_orders_bool_exp
    $filter: trade_orders_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [trade_orders_order_by!]
    $distinct_on: [trade_orders_select_column!]
  ) {
    objects: trade_orders(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
      uid
      logistics_info
      created_at
      updated_at
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      status

      price_per_unit
      number_of_units
      total_amount
      from_date
      active
      to_date
      advance_amount
      advance_payment_percentage
      status
      days_to_maturity
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_SERVICE_ORDERS = gql`
  query GET_SERVICE_ORDERS(
    $where: service_orders_bool_exp
    $filter: service_orders_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [service_orders_order_by!]
    $distinct_on: [service_orders_select_column!]
  ) {
    objects: service_orders(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
      days_to_maturity
      created_at
      active
      uid
      created_at
      updated_at
      total_amount
      price_per_unit
      number_of_units
      service_type {
        pictures
        pictures
        active
        id
        name
        service_id
        service {
          pictures
          active
          id
          name
        }
      }
      date
      provider_service_id
      buyer {
        active
        id
        email
        photo_url
      }
      seller {
        active
        id
        email
        photo_url
      }
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }

      status

      status
    }
  }
`;

export const SEARCH_MAIN = gql`
  query search_main($text: String!, $type: String!) {
    matched: search_main(
      where: { name: { _ilike: $text }, type: { _ilike: $type } }
      limit: 8
      order_by: { name: asc }
    ) {
      type
      id
      name
      parent_id
      parent_name
      pictures
    }
  }
`;

export const SEARCH_TRANSLATION = gql`
  query search_translation($text: String!) {
    matched: translations(
      where: { text: { _ilike: $text } }
      limit: 100 #  distinct_on: map_id
    ) {
      text
      id
      map_id
      language
    }
  }
`;

export const GET_ENTITY_BY_MAP_ID = gql`
  query getEntityByMapId($map_id: [uuid]!) {
    products(where: { id: { _in: $map_id } }) {
      id
      name
      pictures
    }
    services(where: { id: { _in: $map_id } }) {
      id
      name
      pictures
    }
    product_types(where: { id: { _in: $map_id } }) {
      id
      name
      pictures
      product {
        id
        name
      }
    }
    service_types(where: { id: { _in: $map_id } }) {
      id
      name
      pictures
      service {
        id
        name
      }
    }
  }
`;

export const MATCHING_CONNECTIONS = gql`
  query MATCHING_CONNECTIONS($user_id: String!, $limit: Int) {
    matching: users_by_pk(id: $user_id) {
      id
      objects: matching_offers_by_product_type_date(limit: $limit) {
        uid
        created_at
        updated_at
        active
        id
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        product_type {
          product {
            pictures
            name
          }
          name
        }
        status
        total_amount
        from_date
        to_date
        request_id
      }
    }
  }
`;

export const GET_TAXES = gql`
  query GET_TAXES($where: taxes_bool_exp) {
    taxes(where: $where) {
      id
      name
      value
      value_type
      valid_from
      valid_to
      country
      apply_for
    }
  }
`;

export const GET_PAYMENTS = gql`
  query GET_PAYMENTS($where: payments_bool_exp) {
    payments(where: $where) {
      active
      advance
      amount
      buyer_id
      collected_amount
      collection_fee
      completed_at
      created_at
      id
      paymentstatus
      paymenttype
      trade_order_id
      status
      service_order_id
    }
  }
`;
export const GET_PAYMENTS_EXPORT = gql`
  query GET_PAYMENTS_EXPORT($where: payments_bool_exp) {
    payments(where: $where) {
      id
      amount
      status
      created_at
      buyer_id
      buyer {
        name
        phone_number
      }
      seller_id
      seller {
        name
        phone_number
      }
      active
      advance
      collected_amount
      collection_fee
      completed_at
      paymentstatus
      paymenttype
      stripe_session_id
      trade_order_id
      uid
      updated_at
      service_order_id
      pg
      pg_request_no
      redirect_url
      session_info
      timestamp
      #   trade_orders {
      #   request {
      #     commission_rate
      #     request_id
      #   }
      # }
    }
  }
`;
export const GET_QUALITY_AUDITOR_PARENTS = gql`
  query GET_QUALITY_AUDITOR_PARENTS {
    quality_auditor_parents {
      id
      name
    }
  }
`;

export const SEARCH_QUALITY_AUDITOR_PARENTS = gql`
  query quality_auditor_parents($text: String!) {
    quality_auditor_parents(where: { name: { _like: $text } }) {
      id
      name
    }
  }
`;

// export const GET_REVIEWS = gql`
//   query GET_REVIEWS($where: reviews_bool_exp, $order_by: [reviews_order_by]) {
//     objects: reviews(where: $where, order_by: $order_by) {
//       buyer_id
//       created_at
//       headline
//       id
//       user_id
//       user {
//         name
//         photo_url
//       }
//       updated_at
//       seller_id
//       review
//       request_id
//       rating
//       provider_service_id
//       pictures
//     }

//     totals: reviews_aggregate(where: $where) {
//       aggregate {
//         avg {
//           rating
//         }
//         count
//       }
//     }
//   }
// `;
export const GET_DEFAULT_COMMISSION_RATE = gql`
  query commissions {
    commissions(where: { active: { _eq: true } }) {
      commission_rate
      id
    }
  }
`;

export const WEATHER_FORECAST = gql`
  query weather_forecast($lat: Float!, $lng: Float!, $days: Int) {
    weather_forecast(lat: $lat, lng: $lng, days: $days) {
      alerts {
        effective
        event
        headline
        expires
        note
        severity
      }
      current {
        humidity
        precip_mm
        temp_c
        wind_kph
        condition {
          code
          text
        }
      }
      forecast {
        date
        day {
          avgtemp_c
          maxtemp_c
          mintemp_c
        }
      }
    }
  }
`;

export const WEATHER_ALERT_STATUS = gql`
  query weather_alert_status($locations: [Locations]!) {
    weather_alert_status(locations: $locations) {
      status
    }
  }
`;

// export const GET_QR_File = gql`
//   query files_by_pk($id: file_id) {
//     id
//     name
//     file
//   }
// `;
