import { Table } from "antd";
import dayjs from "dayjs";
import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { GeneralContext } from "../general";
import { makeTree, updateKeyIndex } from "../helpers/utils";
import { useTranslatedLabel } from "../hooks";

export const CustomTable = (props) => {
	const { general_language } = useContext(GeneralContext);
	const { t } = useTranslatedLabel();
	CustomTable.t = t;
	let dataSource = updateKeyIndex(props.data);
	if (props.tree) dataSource = makeTree(dataSource);
	const columns = props.columns.map((e) => ({ ...e, align: general_language.includes("ar") ? "right" : "left" }));

	return (
		<div className={`container bg-white p-3 ${props.onRowClick && "table-cursor"}`}>
			<Table
				bordered
				columns={props.tree ? [{ key: "id" }, ...columns] : columns}
				dataSource={dataSource}
				pagination={{
					defaultPageSize: props.defaultPageSize || 10,
					position: ["bottomRight"],
					size: "default",
					// total: props.data.length,
					showTotal: (total, range) => `${range[0]}-${range[1]} | ${t("total")}: ${total}`,
				}}
				size="small"
				rowKey="id"
				expandable={props.expandable ?? false}
				{...props}
			/>
		</div>
	);
};

CustomTable.MoneyRenderer = (field, path) => (row) =>
	row ? (
		<span className="pull-right">
			{path ? (
				<Link to={path(row)}>
					<NumberFormat value={parseFloat(row[field]).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$ "} />
				</Link>
			) : (
				<NumberFormat value={parseFloat(row[field]).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$ "} />
			)}
		</span>
	) : (
		""
	);
CustomTable.NumberRenderer = (field, path) => (row) =>
	row ? (
		<span className="pull-right">
			{path ? (
				<Link to={path(row)}>
					<NumberFormat value={row[field]} displayType={"text"} thousandSeparator={true} />
				</Link>
			) : (
				<NumberFormat value={row[field]} displayType={"text"} thousandSeparator={true} />
			)}
		</span>
	) : (
		""
	);
CustomTable.AggregateCountRenderer = (field, path) => (row) =>
	row && row[field] ? (
		<span className="pull-right">
			{path ? (
				<Link to={path(row)}>
					<NumberFormat value={row[field]?.aggregate?.count} displayType={"text"} thousandSeparator={true} />
				</Link>
			) : (
				<NumberFormat value={row[field]?.aggregate?.count} displayType={"text"} thousandSeparator={true} />
			)}
		</span>
	) : (
		""
	);
CustomTable.AggregateSumRenderer = (field, inner, path) => (row) =>
	row && row[field] ? (
		<span className="pull-right">
			{path ? (
				<Link to={path(row)}>
					<NumberFormat value={row[field]?.aggregate?.sum[inner]} displayType={"text"} thousandSeparator={true} />
				</Link>
			) : (
				<NumberFormat value={row[field]?.aggregate?.sum[inner]} displayType={"text"} thousandSeparator={true} />
			)}
		</span>
	) : (
		""
	);
CustomTable.ProductRenderer = (row) => (
	<span className="">{CustomTable.t(row?.product_type?.product?.id, row?.product_type?.product?.name)}</span>
);
CustomTable.VarietyRenderer = (row) => <span className="">{CustomTable.t(row?.product_type?.id, row?.product_type?.name)}</span>;
CustomTable.ServiceRenderer = (row) => (
	<span className="">{CustomTable.t(row?.service_type?.service?.id, row?.service_type?.service?.name)}</span>
);
CustomTable.CategoryRenderer = (row) => <span className="">{CustomTable.t(row?.service_type?.id, row?.service_type?.name)}</span>;
CustomTable.DateRenderer = (field, dt) => (row) => (row && row[field] ? <span className="pull-right">{`${dt(row[field])}`}</span> : "");
CustomTable.UserRenderer = (field) => (row) => (row && row[field] ? <Link to={`/user/${row[field]?.id}`}>{row[field]?.name}</Link> : "");
CustomTable.LocationRenderer = (field) => (row) => (row && row[field] ? row[field]?.city : "");
CustomTable.RoleRenderer = (row) => {
	const { agent_access, buyer_access, grower_access, provider_access, moderator_access } = row;
	if (agent_access) return CustomTable.t("agent");
	if (buyer_access) return CustomTable.t("buyer");
	if (grower_access) return CustomTable.t("grower");
	if (provider_access) return CustomTable.t("provider");
	if (moderator_access) return CustomTable.t("moderator");
};

CustomTable.GenericSorter = (field) => (a, b) => a[field] && b[field] && a[field] - b[field];
CustomTable.TextSorter = (field) => (a, b) => a[field] && b[field] && a[field].localeCompare(b[field]);
CustomTable.ProductSorter = (a, b) => a?.product_type?.product?.name?.localeCompare(b?.product_type?.product?.name);
CustomTable.VarietySorter = (a, b) => a?.product_type?.name?.localeCompare(b?.product_type?.name);
CustomTable.ServiceSorter = (a, b) => a?.service_type?.service?.name?.localeCompare(b?.service_type?.service?.name);
CustomTable.CategorySorter = (a, b) => a?.service_type?.name?.localeCompare(b?.service_type?.name);
CustomTable.UserSorter = (field) => (a, b) => a[field] && b[field] && `${a[field]?.name || ""}`.localeCompare(`${b[field]?.name || ""}`);
CustomTable.LocationSorter = (field) => (a, b) => a[field] && b[field] && `${a[field]?.city || ""}`.localeCompare(`${b[field]?.city || ""}`);
CustomTable.DateSorter = (field) => (a, b) => a[field] && b[field] && dayjs(a[field]).millisecond() - dayjs(b[field]).millisecond();
CustomTable.AggregateCountSorter = (field) => (a, b) => a[field] && b[field] && a[field]?.aggregate?.count - b[field]?.aggregate?.count;
CustomTable.AggregateSumSorter = (field, inner) => (a, b) =>
	a[field] && b[field] && a[field]?.aggregate?.sum[inner] - b[field]?.aggregate?.sum[inner];
