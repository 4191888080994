import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
} from "react-google-maps";
import { APP_CONFIG } from "../app_config";
import { useTranslatedLabel } from "../hooks";
import { getGeocodeData } from "./geocode";

// export const useTranslate = (id, alt) => useTranslatedLabel().t(id, alt);
// export const useDateTranslate = (date, db, format) => useTranslatedLabel().dt(date, db, format);
// export const useDocTitle = (id, alt) => useDocumentTitle(`${useTranslate(id, alt)} | ${APP_CONFIG.NAME}`);
export const AVATAR_URL = APP_CONFIG.REACT_APP_USER_AVATAR_URL;

export const useDocTitle = (id, alt) =>
  (document.title = `${useTranslatedLabel().t(
    id,
    alt
  )} | ${useTranslatedLabel().t("app_name")}`);
// export const useDocTitle = (id, alt) =>
// (document.title = `${useTranslatedLabel().t(id, alt)} | ${useTranslatedLabel().t("app_name")} - ${useTranslatedLabel().t("app_desc")}`);

export const getThumbNail = (url) =>
  url.replace("image/upload/", "image/upload/c_thumb,w_200,g_face/");
export const getGallaryThumbNail = (url) =>
  url.replace("image/upload/", "image/upload/c_thumb,w_100,h_100,g_face/");

export const getParams = (url) => {
  // we'll store the parameters here
  let pathObj = {};

  const pathString = url
    ? url.split("?")[0]
    : window.location.pathname.slice(1);
  pathObj.pathString = pathString;
  // if path string exists
  if (pathString) {
    // split our query string into its component parts
    const args = pathString.split("/");
    pathObj.args = args;
    for (let i = 0; i < args.length; i++) {
      pathObj["arg_" + i] = args[i];
    }
  }

  // stuff after # is not part of query string, so get rid of it
  const queryString = (
    url ? url.split("?")[1] : window.location.search.slice(1)
  ).split("#")[0];

  // if query string exists
  if (queryString) {
    pathObj.queryString = queryString;
    pathObj.params = queryStringToJSON(queryString);
  } else {
    pathObj.params = {};
  }
  return pathObj;
};

function queryStringToJSON(qs) {
  var pairs = qs.split("&");
  var result = {};
  pairs.forEach(function (p) {
    var pair = p.split("=");
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || "");
    if (key.includes("_ids") && value) value = JSON.parse(value);

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === "[object Array]") {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
}

export const getNewPath = ({
  updates = {},
  fields = [],
  url = window.location.pathname,
}) => {
  const { params } = getParams();
  let newParams = { ...(fields?.length > 0 ? params : {}) };
  fields.map((e) => {
    delete newParams[e];
    return e;
  });
  newParams = { ...newParams, ...updates };

  let str = "";
  for (const [key, value] of Object.entries(newParams)) {
    if (Array.isArray(value) && value?.length === 0) {
    } else {
      if (str) str += "&";
      str += `${key}=${Array.isArray(value) ? JSON.stringify(value) : value}`;
    }
  }

  return `${url}?${str}`;
};
export const truncateNumber = (number, nodecimal) =>
  Math.abs(
    nodecimal ? parseInt(number) : Math.round(parseFloat(number) * 100) / 100
  );
export const slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 9,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
  ],
};

export const PreviewMap = withGoogleMap(
  ({
    data = {
      name: "X",
      mapCenter: {
        lat: 8.165975,
        lng: 77.445312,
      },
      markerPosition: {
        lat: 8.165975,
        lng: 77.445312,
      },
    },
    isMarkerShown = true,
    loadingElement = <div style={{ height: `15rem` }} />,
    containerElement = <div style={{ height: `15rem` }} />,
    mapElement = <div style={{ height: `100%` }} />,
  }) => (
    <GoogleMap defaultZoom={12} defaultCenter={data?.mapCenter}>
      <InfoWindow
        position={{
          lat: data?.markerPosition?.lat + 0.0018,
          lng: data?.markerPosition?.lng,
        }}>
        <span
          style={{
            padding: 0,
            margin: 0,
          }}>{`${data.address_line_1}, ${data.city}, ${data.state}`}</span>
      </InfoWindow>
      <Marker draggable={false} />
      <Marker />
    </GoogleMap>
  )
);
export const FullMap = withGoogleMap(
  ({
    data = {
      name: "X",
      mapCenter: {
        lat: 8.165975,
        lng: 77.445312,
      },
      markerPosition: {
        lat: 8.165975,
        lng: 77.445312,
      },
    },
    onMarkerDragEnd = () => {},
    isMarkerShown = true,
    loadingElement = <div style={{ height: `15rem` }} />,
    containerElement = <div style={{ height: `15rem` }} />,
    mapElement = <div style={{ height: `100%` }} />,
  }) => (
    <GoogleMap defaultZoom={15} defaultCenter={data.mapCenter}>
      <InfoWindow
        position={{
          lat: data.markerPosition.lat + 0.0018,
          lng: data.markerPosition.lng,
        }}>
        <span
          style={{
            padding: 0,
            margin: 0,
          }}>{`${data.address_line_1}, ${data.city}, ${data.state}`}</span>
      </InfoWindow>
      <Marker
        draggable={true}
        onDragEnd={onMarkerDragEnd}
        position={data.markerPosition}
      />
      <Marker />
    </GoogleMap>
  )
);

export const makeTree = (array = [], parent, field = "offer_id") => {
  let tree = [];
  parent = parent ? parent : { id: null };

  var children = array?.filter((child) => child && child[field] === parent.id);
  if (children?.length > 0) {
    if (parent.id === null) tree = children;
    else parent.children = children;
    children.map((child) => makeTree(array, child, field));
  }
  return tree;
};

export const updateKeyIndex = (array, refetch) => {
  let updatedArray = [];
  for (let i = 0; i < array.length; i++) {
    let e = { ...array[i] };
    if (!e) continue;
    if (!e.key) e.key = e.id ? e.id : Math.random();
    if (!e.index) e.index = i;
    if (refetch) e.refetch = refetch;
    if (!(e.children && e.children.length > 0)) delete e.children;
    updatedArray.push(e);
  }
  return updatedArray;
};

export const hexToRgbA = (hex) => {
  let c;
  c = hex.substring(1).split("");
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = "0x" + c.join("");
  return `rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)`;
};
function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
export const invertColor = (hex, bw) => {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
};

export const svg_icons = {
  citrus: require("../../images/svg/citrus.svg"),
  strawberries: require("../../images/svg/strawberry.svg"),
  grapes: require("../../images/svg/grape.svg"),
  mango: require("../../images/svg/mango.svg"),
  dates: require("../../images/svg/dates.svg"),
  pomegranate: require("../../images/svg/pomegranate.svg"),
  peanut: require("../../images/svg/peanut.svg"),
  potato: require("../../images/svg/potato.svg"),
  onion: require("../../images/svg/onion.svg"),
  flowers: require("../../images/svg/bouquet.svg"),
};

/**
 * Calculate the center/average of multiple GeoLocation coordinates
 * Expects an array of objects with .latitude and .longitude properties
 *
 * @url http://stackoverflow.com/a/14231286/538646
 */
export const averageGeolocation = (coords) => {
  if (coords.length === 1) return coords[0];

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  if (coords && coords.length > 0)
    for (let coord of coords) {
      if (coord && coord.lat && coord.lng) {
        let latitude = (coord.lat * Math.PI) / 180;
        let longitude = (coord.lng * Math.PI) / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
      }
    }

  let total = coords.length || 1;

  x = x / total;
  y = y / total;
  z = z / total;

  let centralLongitude = Math.atan2(y, x);
  let centralSquareRoot = Math.sqrt(x * x + y * y);
  let centralLatitude = Math.atan2(z, centralSquareRoot);
  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI,
    key: Math.random(),
  };
};

export const cleanEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === "object" ? cleanEmpty(v) : v))
      .filter((v) => !(v == null));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? cleanEmpty(v) : v])
      .reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
  }
};
export const toTitleCase = (str) => {
  if (str)
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  else return "";
};

export const PreviewGoogleMap = withGoogleMap(
  ({ positions, zoom, isMarkerShown }) => (
    <GoogleMap
      defaultZoom={zoom || 4}
      center={averageGeolocation(positions)}
      defaultCenter={averageGeolocation(positions)}>
      {isMarkerShown &&
        positions.map((position) =>
          position ? (
            <Marker
              position={position}
              key={position.id}
              icon={position.icon || ""}
            />
          ) : (
            <span />
          )
        )}
    </GoogleMap>
  )
);

export const getMapPoint = (e) => {
  if (e?.markerPosition)
    return {
      ...e,
      ...e.markerPosition,
      id: Math.random(),
      icon: e?.icon,
    };
  else
    return {
      ...e,
      lat: e?.lat || 0,
      lng: e?.lng || 0,
      id: Math.random(),
      icon: e?.icon,
    };
};

export const getLocationData = async (
  latitude = 0,
  longitude = 0,
  countries = []
) => {
  try {
    const geocodeData = await getGeocodeData({ lat: latitude, lng: longitude });
    const address = geocodeData.results[0].formatted_address;
    const addressArray = geocodeData.results[0].address_components;

    let location = {
      fullAddress: address,
      address_line_1: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
      mapCenter: {
        lat: latitude ?? 8.165975,
        lng: longitude ?? 77.445312,
      },
      markerPosition: {
        lat: latitude ?? 8.165975,
        lng: longitude ?? 77.445312,
      },
    };
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types.includes("locality"))
        location.city = addressArray[i].long_name;
      else if (addressArray[i].types.includes("administrative_area_level_2"))
        location.city = addressArray[i].long_name;
      if (addressArray[i].types.includes("administrative_area_level_1"))
        location.state = addressArray[i].long_name;
      if (addressArray[i].types.includes("postal_code"))
        location.postcode = addressArray[i].long_name;

      if (addressArray[i].types.includes("country"))
        location.country = countries.filter(
          (e) => e.comment === addressArray[i].long_name
        )[0]?.id;
    }

    for (let j = 0; j < addressArray.length; j++) {
      if (
        addressArray[j].types.includes("locality") ||
        addressArray[j].types.includes("administrative_area_level_2") ||
        addressArray[j].types.includes("administrative_area_level_1") ||
        addressArray[j].types.includes("country")
      )
        break;
      else {
        if (location.address_line_1) location.address_line_1 += ", ";
        location.address_line_1 += `${addressArray[j].long_name}`;
      }
    }
    return location;
  } catch (err) {
    return {};
  }
};

export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to window.localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export const getBrowserLanguage = (languages) => {
  var userLang = (window.navigator.language || window.navigator.userLanguage)
    .toLowerCase()
    .replace("-", "_");
  if (languages) {
    const valid = languages.filter((lang) => lang.value === userLang);
    if (valid.length > 0) {
      return userLang;
    } else {
      const similarLang = languages.filter(
        (lang) => lang.value.split("_", 1)[0] === userLang.split("_", 1)[0]
      );
      return similarLang.length > 0 ? similarLang[0].value : "ar_eg";
    }
  } else {
    return "ar_eg";
  }
};
