// -- Weatherstack API endpoint
import day from "../../../icons/weather/day.svg";
import cloudy from "../../../icons/weather/cloudy.svg";
import cloudyday1 from "../../../icons/weather/cloudy-day-1.svg";
// import cloudyday2 from "../../../icons/weather/cloudy-day-2.svg";
// import cloudyday3 from "../../../icons/weather/cloudy-day-3.svg";
import thunder from "../../../icons/weather/thunder.svg";
import rainy1 from "../../../icons/weather/rainy-1.svg";
// import rainy2 from "../../../icons/weather/rainy-2.svg";
// import rainy3 from "../../../icons/weather/rainy-3.svg";
// import rainy4 from "../../../icons/weather/rainy-4.svg";
// import rainy5 from "../../../icons/weather/rainy-5.svg";
// import rainy6 from "../../../icons/weather/rainy-6.svg";
// import rainy7 from "../../../icons/weather/rainy-7.svg";
import snowy1 from "../../../icons/weather/snowy-1.svg";
// import snowy2 from "../../../icons/weather/snowy-2.svg";
// import snowy3 from "../../../icons/weather/snowy-3.svg";
// import snowy4 from "../../../icons/weather/snowy-4.svg";
// import snowy5 from "../../../icons/weather/snowy-5.svg";
// import snowy6 from "../../../icons/weather/snowy-6.svg";
import { APP_CONFIG } from "../../../shared/app_config";

const accessToken = APP_CONFIG.REACT_APP_WEATHERAPI_ACCESS_TOKEN || "277e1056d0d3405788180350221303";

export const weatherApiEndpoint = `https://api.weatherapi.com/v1/current.json?key=${accessToken}`;

export const weatherForecastApiEndpoint = `https://api.weatherapi.com/v1/forecast.json?key=${accessToken}`;

export const weathericons = {
  113: day,
  116: cloudyday1,
  119: cloudy,
  122: cloudy,
  143: cloudy,
  176: rainy1,
  179: snowy1,
  182: rainy1,
  185: rainy1,
  200: thunder,
  227: snowy1,
  230: snowy1,
  248: cloudy,
  260: cloudy,
  263: rainy1,
  266: rainy1,
  281: rainy1,
  284: rainy1,
  293: rainy1,
  296: rainy1,
  299: rainy1,
  302: rainy1,
  305: rainy1,
  308: rainy1,
  311: rainy1,
  314: rainy1,
  317: rainy1,
  320: rainy1,
  323: snowy1,
  326: snowy1,
  329: snowy1,
  332: snowy1,
  335: snowy1,
  338: snowy1,
  350: snowy1,
  353: cloudyday1,
  356: cloudyday1,
  359: cloudyday1,
  362: cloudyday1,
  365: cloudyday1,
  368: snowy1,
  371: snowy1,
  374: snowy1,
  377: snowy1,
  386: thunder,
  389: thunder,
  392: thunder,
  395: thunder,
};
