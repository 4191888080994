import React from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_ONE } from "../../gql";
import defaultNotificationImage from "../../../../images/default-notification-image.png";
import { useTranslatedLabel } from "../../../../shared/hooks";

export const NotificationListItem = ({ notification, is_default = false }) => {
  const history = useHistory();
  const { t } = useTranslatedLabel();
  const [updateNotification] = useMutation(UPDATE_ONE("notifications"));

  const visitNotificationLink = async ({ id: notificationId, cta_link }) => {
    try {
      history.push(cta_link);
      await updateNotification({
        variables: { id: notificationId, object: {} },
      });
    } catch (err) {
      console.warn(err);
    }
  };

  const renderContent = (type, message = false) => {
    switch (type) {
      case "new_marketplace_sell_offer":
        return (
          <>
            <p className="mb-0 sm-text font-weight-bold"> {t("new_offer_notify_header", "Your offer is now live!")}</p>
            {message && (
              <p className="mb-2 sm-text">
                {t(
                  "new_sell_offer_notify_message",
                  "Congrats on publishing your new sell offer! What a perfect time to share your offer details with the whole world and get more reach."
                )}
              </p>
            )}
            <p className="mb-2 sm-text">{t("view_sell_offer", "View Sell Offer")}</p>
          </>
        );
      case "new_marketplace_buy_offer":
        return (
          <>
            <p className="mb-0 sm-text font-weight-bold"> {t("new_offer_notify_header", "Your offer is now live!")}</p>
            {message && (
              <p className="mb-2 sm-text">
                {t(
                  "new_buy_offer_notify_message",
                  "Congrats on publishing your new buy offer! What a perfect time to share your offer details with the whole world and get more reach."
                )}
              </p>
            )}
            <p className="mb-2 sm-text">{t("view_buy_offer", "View Buy Offer")}</p>
          </>
        );
      case "new_service_offer":
        return (
          <>
            <p className="mb-0 sm-text font-weight-bold"> {t("new_offer_notify_header", "Your offer is now live!")}</p>
            {message && (
              <p className="mb-2 sm-text">
                {t(
                  "new_service_offer_notify_message",
                  "Congrats on publishing your new service offer! What a perfect time to share your offer details with the whole world and get more reach."
                )}
              </p>
            )}
            <p className="mb-2 sm-text">{t("view_service_offer", "View Service Offer")}</p>
          </>
        );
      case "counter_offer":
        return (
          <>
            <p className="mb-0 sm-text font-weight-bold"> {t("bid_submitted_notify_header", "Your bid has been submitted!")}</p>
            {message && (
              <p className="mb-2 sm-text">{t("bid_submitted_notify_message", "Thanks for submitting your new bid!.")}</p>
            )}
            <p className="mb-2 sm-text">{t("view_offer")}</p>
          </>
        );
      case "accept_offer":
        return (
          <>
            <p className="mb-0 sm-text font-weight-bold">
              {" "}
              {`${t("bid_acceptance_notify_header", "Your bid acceptance has been submitted!")}`}
            </p>
            {message && <p className="mb-2 sm-text">{t("bid_acceptance_notify_message", "Thanks for accepting the offer!.")}</p>}
            <p className="mb-2 sm-text">{t("view_offer")}</p>
          </>
        );
      case "welcome":
        return (
          <>
            <p className="mb-0 sm-text font-weight-bold">
              {`${t("welcome_notify_header", "Welcome to Mahaseel,")}, ${(notification?.header).split(",")[1]} `}
            </p>
            {message && (
              <p className="mb-2 sm-text">
                {t(
                  "welcome_notify_message",
                  "Mahaseel is a global agricultural marketplace where you source agricultural products and services from anywhere in the globe. Take advantage of our global network of suppliers and start sourcing from verified suppliers from countries all over the world."
                )}
              </p>
            )}
            <p className="mb-2 sm-text">
              {notification?.user_role === "provider" ? t("explore_services") : t("explore_marketplace")}
            </p>
          </>
        );

      default:
        return (
          <>
            <p className="mb-0 sm-text font-weight-bold"> {notification.header}</p>
            {message && <p className="mb-2 sm-text">{notification.message}</p>}
            <p className="mb-2 sm-text">{notification.cta_title}</p>
          </>
        );
    }
  };

  return (
    <>
      {!is_default && (
        <div
          className={
            notification.active
              ? `notification-list-item p-2 d-flex align-items-center notification-list-item-active`
              : `notification-list-item p-2 d-flex align-items-center`
          }
          onClick={() => visitNotificationLink(notification.type)}
        >
          <div className="notification-item-avatar" style={{ backgroundImage: `url(${defaultNotificationImage})` }} />
          <div className="flex-grow-1 text-left">
            {renderContent(notification.type)}
            <p className="mb-0 text-gray sm-text-2">{dayjs(notification.created_at).format("h:mm A MMMM D, YYYY")}</p>
          </div>
        </div>
      )}
      {is_default && (
        <div className="notification-default-item text-center">
          <p className="mb-2">
            <i className="las la-check"></i>
          </p>
          <p className="text-gray mb-0 sm-text">{t("empty_notification")}</p>
        </div>
      )}
    </>
  );
};
