import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/ja';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh';
import React, { useContext } from 'react';
import { GeneralContext } from '../general';
import { toTitleCase } from '../helpers';

export const useTranslatedLabel = () => {
  const { general_language, general_translations_map } =
    useContext(GeneralContext);

  function translate(id, alt) {
    return (
      general_translations_map[
        id?.toLowerCase().replace(new RegExp(' ', 'g'), '_')
      ] ||
      alt ||
      (id ? toTitleCase(id.replace(new RegExp('_', 'g'), ' ')) : '')
    );
  }
  function translateText(id, alt) {
    return <span className="translation-text">{translate(id, alt)}</span>;
  }
  function date_translate(date, format) {
    return dayjs(date)
      .locale(general_language.slice(0, 2))
      .format(format || 'MMM DD, YYYY');
  }
  function datetime_translate(date, long, format) {
    return dayjs(date)
      .locale(general_language.slice(0, 2))
      .format(format || 'MMM DD, YYYY hh:mm A');
  }
  function db_date_translate(date) {
    return dayjs(date).format('YYYY-MM-DD');
  }

  return {
    t: translate,
    tt: translateText,
    dt: date_translate,
    dt_tm: datetime_translate,
    dt_db: db_date_translate,
  };
};
