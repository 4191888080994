import Prismic from "prismic-javascript";
import React, { useContext, useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { APP_CONFIG } from "../../../../shared/app_config";
import { useLocalStorage } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { webpageclient } from "../../components/prismicHelpers";
import { PATHS } from "../../config";
import { logout } from "../../services";
import { AuthContext, GeneralContext, WeatherContext } from "../../store";
import { Notifications } from "../notifications";
import { MobileDrawer } from "./mobile-drawer";
import { WeatherAlertHeader } from "./weather-alert-header";
import newLogo from "../../../assets/header/logo.png";

import { neededLangs } from "../../config/languages";

const AuthHeader = () => {
  const {
    general_language,
    setGeneralLanguage,
    general_languages,
    general_languages_map,
  } = useContext(GeneralContext);
  const {
    user = {},
    logoutUser,
    is_authenticated,
    user_role,
  } = useContext(AuthContext);
  const { alertStatus } = useContext(WeatherContext);
  const { name, photo_url } = user;
  const { t } = useTranslatedLabel();
  const [, setPrismicData] = useState();
  const [, setPath] = useLocalStorage("path", window.location.pathname);

  // Get the homepage and blog post documents from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const homeDoc = await webpageclient.query(
          Prismic.Predicates.at("document.type", "homepage"),
          {
            lang: (general_language || "en_gb").replace("_", "-"),
          }
        );

        if (homeDoc && homeDoc.results && homeDoc.results[0]) {
          setPrismicData(homeDoc.results[0]);
        } else {
          console.warn(
            "Blog Home document was not found. Make sure it exists in your Prismic repository"
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPrismicData();
  }, [general_language]);

  const logoutClick = async (e) => {
    e.preventDefault();
    logoutUser();
    await logout();
  };

  return (
    <>
      <header className="header" style={{ padding: "0px" }}>
        <div className="p-0 m-0 row col bg-secondary">
          <div className="container">
            <Navbar className="p-0 m-0">
              <Nav className="mx-auto"></Nav>
              {is_authenticated && (
                <Nav className="ml-2">
                  <Nav.Item className="p-0 m-0 ml-2">
                    <a
                      className="text-primary"
                      href={PATHS.home}
                      style={{ fontSize: 12 }}>
                      {t("home")}
                    </a>
                  </Nav.Item>
                </Nav>
              )}
              {is_authenticated && (
                <Nav className="ml-2">
                  <Nav.Item className="p-0 m-0 ml-2">
                    <small className="transaction-dropdown">
                      <p className="p-0 m-0" style={{ fontSize: 12 }}>
                        {t("about_us")}
                      </p>
                      <div className="transaction-dropdown-menu">
                        <>
                          {/* <Link
                            to={PATHS.forgrowers}
                            className="dropdown-item"
                            style={{ fontSize: 12 }}
                          >
                            {t('growers')}
                          </Link> */}
                          {/* <NavDropdown title={t('How_it_works')}>
                            <NavDropdown.Item className="dropdownItem">
                              <Link
                                to={PATHS.forgrowers}
                                style={{ color: '#018301de', fontSize: '14px' }}
                              >
                                {t('growers')}
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item className="dropdownItem">
                              <Link
                                to={PATHS.investors}
                                style={{ color: '#018301de', fontSize: '14px' }}
                              >
                                {t('buyers')}
                              </Link>
                            </NavDropdown.Item>
                          </NavDropdown> */}
                          {/* <Link
                            to={PATHS.investors}
                            className="dropdown-item"
                            style={{ fontSize: 12 }}
                          >
                            {t('investors')}
                          </Link> */}
                          <Link
                            to={PATHS.about}
                            className="dropdown-item"
                            style={{ fontSize: 12 }}>
                            {t("our_story")}
                          </Link>
                        </>
                      </div>
                    </small>
                  </Nav.Item>
                </Nav>
              )}

              {general_languages_map[general_language]?.comment && (
                <Nav className="ml-2">
                  <Nav.Item className="p-0 m-0 ml-2 pull-right">
                    <small className="transaction-dropdown">
                      <p className="p-0 m-0" style={{ fontSize: 12 }}>
                        <i className="las la-language la-lg"></i>
                        {general_languages_map[general_language]?.comment ||
                          "English"}
                      </p>
                      <div className="transaction-dropdown-menu">
                        <>
                          {general_languages
                            // .filter((el) => {
                            //   // console.log(el);
                            //   return neededLangs.includes(el.id) ? el : false;
                            // })
                            .map((l, index) => (
                              <Link
                                to="#"
                                className="dropdown-item"
                                key={index}
                                onClick={(e) => {
                                  // console.log(e);
                                  e.preventDefault();
                                  if (neededLangs.includes(l.id)) {
                                    setGeneralLanguage(l.value);
                                  }
                                }}
                                style={{
                                  fontSize: 12,
                                  color: neededLangs.includes(l.id)
                                    ? ""
                                    : "gray",
                                  cursor: neededLangs.includes(l.id)
                                    ? "pointer"
                                    : "not-allowed",
                                }}>
                                {l.comment}
                                {neededLangs.includes(l.id) ? (
                                  ""
                                ) : (
                                  <span>{` (${t("soon").toLowerCase()})`}</span>
                                )}
                              </Link>
                            ))}
                        </>
                      </div>
                    </small>
                  </Nav.Item>
                </Nav>
              )}
              {is_authenticated && (
                <Nav className="ml-2">
                  <Nav.Item className="p-0 m-0 ml-2">
                    <small className="transaction-dropdown">
                      <p className="p-0 m-0" style={{ fontSize: 12 }}>
                        <i className="las la-user la-lg"></i>
                        {name}
                      </p>
                      <div className="transaction-dropdown-menu">
                        <>
                          <Link
                            to={PATHS.account}
                            className="dropdown-item"
                            style={{ fontSize: 12 }}>
                            <>
                              <img
                                src={
                                  photo_url || APP_CONFIG.REACT_APP_AVATAR_URL
                                }
                                alt=""
                                className="mr-2 rounded-circle"
                                style={{ width: "1rem", height: "1rem" }}
                              />
                              <span>{t("profile")}</span>
                            </>
                          </Link>
                          <Link
                            to={PATHS.settings}
                            className="dropdown-item"
                            style={{ fontSize: 12 }}>
                            <i className="mr-2 las la-cog"></i> {t("settings")}
                          </Link>
                          <Link
                            to={PATHS.transactions}
                            className="dropdown-item"
                            style={{ fontSize: 12 }}>
                            <i className="mr-2 la la-money"></i>{" "}
                            {t("transactions")}
                          </Link>
                          {/* <Link
                            to={PATHS.support}
                            className="dropdown-item"
                            style={{ fontSize: 12 }}>
                            <i className="mr-2 la la-headset"></i>{" "}
                            {t("support")}
                          </Link> */}
                          <Link
                            to={"./logout"}
                            className="dropdown-item logout"
                            onClick={logoutClick}
                            style={{
                              backgroundColor: "#108A00",
                              color: "white",
                              border: "none",
                              fontSize: 12,
                              borderRadius: "0",
                            }}>
                            {/* <i className="mr-2 la la-sign-out"></i>{' '} */}
                            {t("logout")}
                          </Link>
                        </>
                      </div>
                    </small>
                  </Nav.Item>
                </Nav>
              )}
              <Nav className="ml-2"></Nav>
            </Navbar>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="p-0 m-0 col-xl-12">
              <div className="navigation">
                <Navbar bg="light" expand="lg">
                  <Link className="navbar-brand" to={"/"}>
                    <img
                      src={newLogo}
                      style={{
                        height: "75px",
                        width: "88px",
                        marginInlineStart: "1.2em",
                      }}
                      alt="mahaseel logo"
                    />
                  </Link>

                  {is_authenticated && (
                    <div className="d-lg-none d-xl-none">
                      <Notifications recipient_id={user.id} />
                    </div>
                  )}
                  <MobileDrawer />

                  {is_authenticated ? (
                    <>
                      <Navbar.Collapse>
                        <Nav className="mx-auto">
                          <Nav.Item>
                            <Link to={PATHS.dashboard}>{t("dashboard")}</Link>
                          </Nav.Item>
                          {user_role !== "provider" && (
                            <Nav.Item className="ml-0">
                              <Link to={PATHS.marketplace}>
                                {t("marketplace")}
                              </Link>
                            </Nav.Item>
                          )}
                          <Nav.Item className="ml-0">
                            <Link to={PATHS.services}>{t("services")}</Link>
                          </Nav.Item>
                          <Nav.Item className="ml-0">
                            <Link to={PATHS.posts}>{t("posts")}</Link>
                          </Nav.Item>

                          {user_role !== "provider" && (
                            <Nav.Item className="ml-2">
                              <div className="transaction-dropdown">
                                <p className="pt-1 mb-1">{t("trade_offers")}</p>
                                <div className="transaction-dropdown-menu">
                                  <Link
                                    to={PATHS.trade_active_offers}
                                    className="dropdown-item">
                                    {t("active_offers")}
                                  </Link>
                                  <Link
                                    to={PATHS.trade_attention_offers}
                                    className="dropdown-item">
                                    {t("requires_attention")}
                                  </Link>
                                  <Link
                                    to={PATHS.trade_submitted_bids}
                                    className="dropdown-item">
                                    {t("submitted_bids")}
                                  </Link>
                                  <Link
                                    to={PATHS.trade_matching_connections}
                                    className="dropdown-item">
                                    {t("matching_connections")}
                                  </Link>
                                  <Link
                                    to={PATHS.trade_offer_history}
                                    className="dropdown-item">
                                    {t("offer_history")}
                                  </Link>
                                  <Link
                                    to={PATHS.trade_bid_history}
                                    className="dropdown-item">
                                    {t("bid_history")}
                                  </Link>
                                </div>
                              </div>
                            </Nav.Item>
                          )}
                          {user_role !== "provider" && (
                            <Nav.Item className="ml-2">
                              <div className="transaction-dropdown">
                                <p className="pt-1 mb-1">{t("trade_orders")}</p>
                                <div className="transaction-dropdown-menu">
                                  <Link
                                    to={PATHS.trade_active_orders}
                                    className="dropdown-item">
                                    {t("active_orders")}
                                  </Link>
                                  <Link
                                    to={PATHS.trade_order_history}
                                    className="dropdown-item">
                                    {t("order_history")}
                                  </Link>
                                </div>
                              </div>
                            </Nav.Item>
                          )}

                          {user_role === "provider" && (
                            <Nav.Item className="ml-2">
                              <div className="transaction-dropdown">
                                <p className="pt-1 mb-1">
                                  {t("service_offers")}
                                </p>
                                <div className="transaction-dropdown-menu">
                                  <Link
                                    to={PATHS.service_active_offers}
                                    className="dropdown-item">
                                    {t("active_offers")}
                                  </Link>
                                  <Link
                                    to={PATHS.service_offer_history}
                                    className="dropdown-item">
                                    {t("offer_history")}
                                  </Link>
                                </div>
                              </div>
                            </Nav.Item>
                          )}
                          <Nav.Item className="ml-2">
                            <div className="transaction-dropdown">
                              <p className="pt-1 mb-1">{t("service_orders")}</p>
                              <div className="transaction-dropdown-menu">
                                <Link
                                  to={PATHS.service_active_orders}
                                  className="dropdown-item">
                                  {t("active_orders")}
                                </Link>
                                <Link
                                  to={PATHS.service_order_history}
                                  className="dropdown-item">
                                  {t("order_history")}
                                </Link>
                              </div>
                            </div>
                          </Nav.Item>
                        </Nav>
                      </Navbar.Collapse>
                      <div className="d-none d-lg-block d-xl-block">
                        <Notifications recipient_id={user.id} />
                      </div>
                    </>
                  ) : (
                    <Navbar.Collapse>
                      <Nav className="mx-auto">
                        <Nav.Item className="mx-auto">
                          <a href={PATHS.home}>{t("home")}</a>
                        </Nav.Item>
                        <Nav.Item className="mx-auto">
                          <Link to={PATHS.marketplace}>{t("marketplace")}</Link>
                        </Nav.Item>
                        <Nav.Item className="mx-auto">
                          <Link to={PATHS.services}>{t("services")}</Link>
                        </Nav.Item>
                        <NavDropdown title={t("How_it_works")}>
                          <NavDropdown.Item className="dropdownItem">
                            <Link
                              to={PATHS.forgrowers}
                              style={{ color: "#338736", fontSize: "14px" }}>
                              {t("growers")}
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item className="dropdownItem">
                            <Link
                              to={PATHS.investors}
                              style={{ color: "#338736", fontSize: "14px" }}>
                              {t("buyers")}
                            </Link>
                          </NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Item className="mx-auto">
                          <Link to={PATHS.investors}>{t('investors')}</Link>
                        </Nav.Item> */}
                        <Nav.Item className="mx-auto">
                          <Link to={PATHS.about}>{t("our_story")}</Link>
                        </Nav.Item>
                        <Nav.Item className="mx-auto">
                          <Link to={PATHS.posts}>{t("posts")}</Link>
                        </Nav.Item>
                        <Nav.Item className="mx-auto">
                          <Link to={PATHS.quotation}>
                            {t("request_for_quotation")}
                          </Link>
                        </Nav.Item>
                        <Nav.Item className="mx-auto">
                          <Link to={PATHS.careers}>{t("careers")}</Link>
                        </Nav.Item>
                        {/* <Nav.Item className="mx-auto">
                            <Link to={PATHS.quotation}>{'Quotation'}</Link>
                        </Nav.Item> */}
                        {/* <Nav.Item className="mx-auto">
                          <Link to={PATHS.ezra3}>ازرع صح</Link>
                        </Nav.Item> */}
                      </Nav>
                    </Navbar.Collapse>
                  )}
                  <Nav className="mx-auto">
                    {!is_authenticated && (
                      <>
                        <Nav.Item className="mx-auto">
                          <Link
                            className="border btn btn-sm btn-light signin-btn d-none d-lg-block"
                            to={PATHS.register}
                            style={{
                              backgroundColor: "#108A00",
                              color: "white",
                              borderRadius: "0",

                              border: "none",
                            }}
                            onClick={() => setPath(`${PATHS.marketplace}`)}>
                            {/* <i className="las la-sign-in-alt la-lg"></i>{" "} */}
                            {t("register")}
                          </Link>
                        </Nav.Item>
                        <Nav.Item className="mx-auto">
                          <Link
                            style={{
                              backgroundColor: "#108A00",
                              color: "white",
                              border: "none",
                              borderRadius: "0",
                            }}
                            to={PATHS.signin}
                            className="border btn btn-sm btn-light signin-btn d-none d-lg-block"
                            onClick={() => setPath(`${PATHS.dashboard}`)}>
                            {/* <i className="las la-user-plus la-lg"></i>{" "} */}
                            {t("login")}
                          </Link>
                        </Nav.Item>
                      </>
                    )}
                  </Nav>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
        {user?.account_verified === false && (
          <div
            className="p-0 m-0 row col"
            style={{ backgroundColor: "#2b77d9", color: "#fff" }}>
            <div className="container">
              <Navbar className="p-0 m-0">
                <Nav className="mr-auto"></Nav>
                <Nav.Item className="p-0 m-0 ml-2">
                  {t("account_verification_pending")}
                </Nav.Item>
                <Nav className="ml-auto"></Nav>
              </Navbar>
            </div>
          </div>
        )}
        {is_authenticated && alertStatus && <WeatherAlertHeader />}
      </header>
    </>
  );
};

export default AuthHeader;
