import React from "react";
import Sugar from "react-preloaders/lib/Sugar/Sugar";
import { ToastProvider } from "react-toast-notifications";
import ClientRoot from "./root";
import ClientGlobalProvider from "./store";

(async () => {
  await import("react-datepicker/dist/react-datepicker.css");
  await import("antd/lib/table/style/index.css");
  await import("antd/lib/button/style/index.css");
  await import("antd/lib/drawer/style/index.css");
  await import("antd/lib/pagination/style/index.css");
  await import("antd/lib/modal/style/index.css");
  await import("antd/lib/spin/style/index.css");
  await import("antd/lib/rate/style/index.css");
  // await import("antd/lib/slider/style/index.css");
  // await import("antd/lib/tooltip/style/index.css");
  await import("rc-slider/assets/index.css");
  await import("react-date-range/dist/styles.css");
  await import("react-date-range/dist/theme/default.css");
  await import("slick-carousel/slick/slick.css");
  await import("slick-carousel/slick/slick-theme.css");
  await import("react-image-gallery/styles/css/image-gallery.css");
  await import("./css/pretty-checkbox.min.css");
  await import("./css/bid-chat.css");
  await import("./css/custom.css");
  await import("./css/responsive.css");
  await import("./css/rtl.css");
})();

const Client = () => {
  return (
    <ClientGlobalProvider>
      <div className="app">
        <ToastProvider
          placement="bottom-right"
          autoDismissTimeout={3000}
          autoDismiss
        >
          <ClientRoot />
          <Sugar color={"#ccd92b"} time={300} />
        </ToastProvider>
      </div>
    </ClientGlobalProvider>
  );
};

export default Client;
