import { Button, Drawer } from 'antd';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { APP_CONFIG } from '../../../../shared/app_config';
import { useTranslatedLabel } from '../../../../shared/hooks';
import { PATHS } from '../../config';
import { logout } from '../../services';
import { AuthContext } from '../../store';
import '../../../../scssNew/mobileDrawer.scss';
import newLogo from '../../../assets/header/logo.png';

export const MobileDrawer = () => {
  const [visible, setVisible] = useState(false);
  const {
    user = {},
    logoutUser,
    is_authenticated,
    user_role,
  } = useContext(AuthContext);
  const { name, photo_url } = user;
  const { t } = useTranslatedLabel();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const logoutClick = async (e) => {
    e.preventDefault();
    logoutUser();
    await logout();
  };

  return (
    <>
      <div className="p-0 ml-3 mr-2 border-0 navbar-toggler">
        <Button onClick={showDrawer} className="p-1">
          <i className="las la-bars hambuger-menu"></i>
        </Button>
        <Drawer
          title={
            <span className="font-weight-bold">
              {is_authenticated ? (
                <>
                  <img
                    src={photo_url || APP_CONFIG.REACT_APP_AVATAR_URL}
                    alt=""
                    className="mr-2 rounded-circle"
                    style={{ width: '1.4rem', height: '1.4rem' }}
                  />
                  <span>{name}</span>
                </>
              ) : (
                <img
                  // src={APP_CONFIG.REACT_APP_LOGO_FLAT_URL}
                  // style={{ height: '50px' }}
                  // alt=""
                  src={newLogo}
                  style={{
                    height: '65px',
                    width: '88px',
                  }}
                  alt="mahaseel logo"
                />
              )}
            </span>
          }
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <div className="h-100 d-flex flex-column justify-content-between">
            <div>
              <div className="mb-3">
                {is_authenticated && (
                  <p className="drawer-link">
                    <Link to={PATHS.dashboard}>{t('dashboard')}</Link>
                  </p>
                )}
                <p className="drawer-link">
                  <a href={PATHS.home}>{t('home')}</a>
                </p>
                <p className="drawer-link">
                  <Link to={PATHS.marketplace}>{t('marketplace')}</Link>
                </p>
                <p className="drawer-link">
                  <Link to={PATHS.services}>{t('services')}</Link>
                </p>
                <p className="drawer-link">
                  <Link to={PATHS.careers}>{t('careers')}</Link>
                </p>
                <p className="drawer-link">
                  <Link to={PATHS.about}>{t('our_story')}</Link>
                </p>
                <p className="drawer-link">
                  <Link to={PATHS.posts}>{t('posts')}</Link>
                </p>
                <p className="mb-0 drawer-link">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="drawer-link howItWorksMobileDrawer"
                    >
                      {t('How_it_works')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link
                          to={PATHS.forgrowers}
                          style={{ color: '#338736', fontSize: '12.8px' }}
                        >
                          {t('growers')}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link
                          to={PATHS.investors}
                          style={{ color: '#338736', fontSize: '12.8px' }}
                        >
                          {t('buyers')}
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </p>
                <p className="drawer-link">
                  <Link to={PATHS.quotation}>{t('request_for_quotation')}</Link>
                </p>
              </div>
              {is_authenticated && user_role !== 'provider' && (
                <>
                  <div className="mb-3">
                    <p className="mb-2 text-gray text-uppercase sm-text border-bottom">
                      {t('trade_offers')}
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_active_offers}>
                        {t('active_offers')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_attention_offers}>
                        {t('requires_attention')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_submitted_bids}>
                        {t('submitted_bids')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_matching_connections}>
                        {t('matching_connections')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_offer_history}>
                        {t('offer_history')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_bid_history}>
                        {t('bid_history')}
                      </Link>
                    </p>
                  </div>
                </>
              )}
              {is_authenticated && user_role !== 'provider' && (
                <>
                  <div className="mb-3">
                    <p className="mb-2 text-gray text-uppercase sm-text border-bottom">
                      {t('trade_orders')}
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_active_orders}>
                        {t('active_orders')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_attention_orders}>
                        {t('requires_attention')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.trade_order_history}>
                        {t('order_history')}
                      </Link>
                    </p>
                  </div>
                </>
              )}
              {is_authenticated && user_role === 'provider' && (
                <>
                  <div className="mb-3">
                    <p className="mb-2 text-gray text-uppercase sm-text border-bottom">
                      {t('service_offers')}
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.service_active_offers}>
                        {t('active_offers')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.service_offer_history}>
                        {t('offer_history')}
                      </Link>
                    </p>
                  </div>
                </>
              )}
              {is_authenticated && (
                <>
                  <div className="mb-3">
                    <p className="mb-2 text-gray text-uppercase sm-text border-bottom">
                      {t('service_orders')}
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.service_active_orders}>
                        {t('active_orders')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.service_attention_orders}>
                        {t('requires_attention')}
                      </Link>
                    </p>
                    <p className="drawer-link">
                      <Link to={PATHS.service_order_history}>
                        {t('order_history')}
                      </Link>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div>
              <div className="text-center d-flex justify-content-between">
                {is_authenticated ? (
                  <>
                    <Link
                      to={'./logout'}
                      className="btn btn-light btn-block text-danger font-weight-bold"
                      onClick={logoutClick}
                    >
                      <i className="la la-sign-out"></i> {t('logout')}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to={PATHS.signin}
                      className="border btn btn-light btn-block signin-btn"
                    >
                      <i className="las la-sign-in-alt la-lg"></i> {t('login')}
                    </Link>
                    <Link
                      className="mt-0 ml-2 btn btn-primary btn-block"
                      to={PATHS.register}
                    >
                      <i className="las la-user-plus la-lg"></i> {t('register')}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
