import React, { createContext, useReducer } from 'react';

const initialState = {
  marketplace_offers: [],
  marketplace_products: [],
  marketplace_product_types: [],
  marketplace_countries: [],
  // marketplace_users: [],
};

const marketplaceActionTypes = {
  SET_OFFERS: 'SET_OFFERS',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_PRODUCT_TYPES: 'SET_PRODUCT_TYPES',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_USERS: 'SET_USERS',
};

const marketplaceReducer = (state, action) => {
  switch (action.type) {
    case marketplaceActionTypes.SET_OFFERS:
      return {
        ...state,
        marketplace_offers: action.payload,
      };
    case marketplaceActionTypes.SET_PRODUCTS:
      return {
        ...state,
        marketplace_products: action.payload,
      };
    case marketplaceActionTypes.SET_PRODUCT_TYPES:
      return {
        ...state,
        marketplace_product_types: action.payload,
      };
    case marketplaceActionTypes.SET_COUNTRIES:
      return {
        ...state,
        marketplace_countries: action.payload,
      };
    // case marketplaceActionTypes.SET_USERS:
    // 	return {
    // 		...state,
    // 		marketplace_users: action.payload,
    // 	};
    default:
      return state;
  }
};

const MarketPlaceContext = createContext(initialState);
const MarketPlaceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(marketplaceReducer, initialState);

  const dispatches = {
    setMarketplaceOffers(e) {
      dispatch({
        type: marketplaceActionTypes.SET_OFFERS,
        payload: e,
      });
    },
    setMarketplaceProducts(e) {
      dispatch({
        type: marketplaceActionTypes.SET_PRODUCT_TYPES,
        payload: e,
      });
    },
    setMarketplaceProductTypes(e) {
      dispatch({
        type: marketplaceActionTypes.SET_PRODUCT_TYPES,
        payload: e,
      });
    },
    setMarketplaceCountries(e) {
      dispatch({
        type: marketplaceActionTypes.SET_COUNTRIES,
        payload: e,
      });
    },
    setMarketplaceUsers(e) {
      dispatch({
        type: marketplaceActionTypes.SET_USERS,
        payload: e,
      });
    },
  };

  return (
    <MarketPlaceContext.Provider value={{ ...state, ...dispatches }}>
      {children}
    </MarketPlaceContext.Provider>
  );
};

export { MarketPlaceContext, MarketPlaceProvider };
