import React, { createContext, useReducer } from "react";

const initialWeatherState = {
  alertStatus: false,
};

const weatherActionTypes = {
  SET_ALERT_STATUS: "SET_ALERT_STATUS",
};

const weatherReducer = (state, action) => {
  switch (action.type) {
    case weatherActionTypes.SET_ALERT_STATUS:
      return {
        ...state,
        alertStatus: action.payload,
      };
    default:
      return state;
  }
};

const WeatherContext = createContext(initialWeatherState);
const WeatherProvider = ({ children }) => {
  const [state, dispatch] = useReducer(weatherReducer, initialWeatherState);

  const dispatches = {
    setAlertStatus(status) {
      dispatch({
        type: weatherActionTypes.SET_ALERT_STATUS,
        payload: status,
      });
    },
  };

  return <WeatherContext.Provider value={{ ...state, ...dispatches }}>{children}</WeatherContext.Provider>;
};

export { WeatherContext, WeatherProvider };
