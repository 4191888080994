import React from "react";
import ReactDOM from "react-dom";
import { Client } from "./apps";
import "./css/style.css";
import * as serviceWorker from "./serviceWorker";
// import ReactGA from "react-ga";
//
// ReactGA.initialize("UA-163580-1");
//
// ReactGA.pageview(window.location.pathname + window.location.search);

const App = Client;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
