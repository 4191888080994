import React from 'react';
import { useContext } from 'react';
import { GeneralContext } from '../../store';
// import { useTranslatedLabel } from "../../../../shared/hooks";

const Footer = () => {
  // const { t, dt } = useTranslatedLabel();
  const { general_language } = useContext(GeneralContext);

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright text-center">
                <p
                  style={{
                    direction: general_language === 'ar_eg' ? 'ltr' : '',
                  }}
                >
                  &copy;{new Date().getFullYear()}, Mahaseel. All Rights
                  Reserved.{' '}
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="d-flex justify-content-end align-items-center locale-social"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
