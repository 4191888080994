import React, { useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { ItemRating } from '.';
import { Spinner } from 'react-bootstrap';
import { useTranslatedLabel } from '../../../../shared/hooks';
import { Button, Modal } from 'react-bootstrap';
import { ImageUploader } from '..';
import { INSERT_REVIEW } from '../../gql';
import { useMutation } from '@apollo/client';
import { uploadImagesToCloudinary } from '../../services';

export const ProductReviewModal = memo(
  ({ offer, openReview, setOpenReview }) => {
    const { t } = useTranslatedLabel();
    const { offerId } = useParams();
    const intitalData = {
      headline: '',
      review: '',
    };

    const [data, setData] = useState(intitalData);
    // const [error, setError] = useState({ headline: false, review: false });
    const [value, setValue] = useState(0);
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);

    const [createReviewMutation] = useMutation(INSERT_REVIEW);

    const onHeadlineChange = (e) => {
      setData({ ...data, headline: e.target.value });
    };
    const onReviewChange = (e) => {
      setData({ ...data, review: e.target.value });
    };

    const onSubmit = async () => {
      setUploading(true);
      let pictures;
      try {
        const response = await uploadImagesToCloudinary(
          images.map((img) => img.file)
        );
        const data = await response.json();
        pictures = data.map((upload) => upload.secure_url);
      } catch (error) {
        console.error(error);
      }

      let _object = {
        headline: data?.headline,
        rating: value,
        review: data?.review,
        pictures: pictures,
      };
      if (offer.__typename === 'requests') {
        _object.request_id = offerId;
      } else if (offer.__typename === 'provider_services') {
        _object.provider_service_id = offerId;
      }

      try {
        createReviewMutation({
          variables: _object,
        });
        setData(intitalData);
        setValue(0);
        setImages([]);
        setUploading(false);
        setOpenReview(false);
      } catch (err) {
        console.error(err);
        setData(intitalData);
        setValue(0);
        setImages([]);
        setUploading(false);
        setOpenReview(false);
      }
    };

    return (
      <Modal
        centered
        size="lg"
        show={openReview}
        onHide={() => setOpenReview(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{t('create_review')}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploading && (
            <div
              className="d-flex position-absolute justify-content-center h-100"
              style={{ width: '96%', backgroundColor: '#f8f9fa8f' }}
            >
              <Spinner
                as="span"
                animation="border"
                user_role="status"
                aria-hidden="true"
                className="my-auto"
              />
            </div>
          )}
          <div>
            <h4 className="m-0">{t('overall_rating')}</h4>
            <ItemRating
              value={value}
              setValue={setValue}
              editable
              size="28px"
            />
          </div>
          <div className="mb-3">
            <h4>{t('add_a_headline')}</h4>
            <input
              type="text"
              // style={{ height: "40px" }}
              placeholder={t("what's_ most_important_to_know?")}
              className={`form-control `}
              name="headline"
              value={data?.headline}
              onChange={onHeadlineChange}
            />
          </div>
          <div className="mb-3">
            <h4>{t('add_a_written_review')}</h4>
            <textarea
              type="text"
              // style={{ height: "40px" }}
              placeholder={t('what_did_you_like_or_dislike?')}
              rows="5"
              className={`form-control`}
              name="review"
              value={data?.review}
              onChange={onReviewChange}
            />
          </div>
          <div className="mb-3">
            <h4>{t('add_photos')}</h4>
            <ImageUploader setImages={setImages} images={images} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!value || value === 0}
            onClick={() => onSubmit()}
          >
            {t('submit')}
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              setOpenReview(false);
              setData(intitalData);
              setValue(0);
              setImages([]);
            }}
          >
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
