export const neededLangs = [
  "en_gb",
  "ar_eg",
  "zh_cn",
  "de_de",
  "ru",
  "es_es",
  "ja_jp",
  "fr_fr",
  "pl",
  "sw",
];

export default neededLangs;
