import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Q_DUMMY } from "../gql";
import { useCountSubscription } from "./countSubscription";

export const useAllObjectUserIdsQuery = ({
  where = {},
  order_by = {},
  query = Q_DUMMY,
  entity,
  field,
  distinct,
  user_id = "",
}) => {
  const [objects, setObjects] = useState([]);
  const total = useCountSubscription(entity, where, field, distinct);
  const { data, error, loading, refetch } = useQuery(query, { variables: { where, order_by, user_id } });

  useEffect(() => {
    if (!loading && !error && data && data.objects) setObjects(data.objects);
  }, [data, error, loading]);

  useEffect(() => {
    refetch();
  }, [refetch, total]);

  return {
    data,
    objects,
    loading,
    total,
    refetch,
  };
};
