import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

export const TypeAheadFilter = ({
	entity = "products",
	query_fields = " id name ",
	where = {},
	filter = {},
	order_by = {},
	field = "name",
	onChange = () => {},
	selected = [],
}) => {
	const [options, setOptions] = useState([]);
	const [searchText, setSearchText] = useState("");
	const QUERY = gql`
		query get_${entity}($where: ${entity}_bool_exp, $order_by: [${entity}_order_by!])
		{ objects: ${entity}(where: $where, order_by: $order_by) { ${query_fields} } }
    `;
	let whereFilter = { ...where, ...filter };
	whereFilter[field] = { _ilike: `%${searchText || ""}%` };

	const { data, error, loading } = useQuery(QUERY, { variables: { where: whereFilter, order_by } });

	useEffect(() => {
		if (data?.objects && !error && !loading) setOptions(data.objects);
	}, [data, error, loading]);

	return (
		<AsyncTypeahead
			id={entity}
			bsSize="sm"
			isLoading={loading}
			clearButton={true}
			minLength={1}
			onSearch={(e) => setSearchText(e)}
			options={options}
			labelKey={field}
			filterBy={(option, props) => option?.[field]?.toLowerCase().indexOf(props.text.toLowerCase()) !== -1}
			useCache={false}
			onChange={onChange}
			selected={selected}
		/>
	);
};
