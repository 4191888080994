import { gql } from '@apollo/client';

export const S_PRODUCTS = gql`
  subscription products(
    $where: products_bool_exp
    $distinct_on: [products_select_column!]
  ) {
    totals: products_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const S_NOTIFICATIONS = gql`
  subscription getUserNotifications($recipient_id: String!) {
    notifications(where: { recipient_user_id: { _eq: $recipient_id } }) {
      id
      active
      header
      message
      type
      cta_link
      cta_title
      created_at
    }
  }
`;

export const GET_USER_BY_ID = gql`
  subscription GET_USER_BY_ID($id: String!) {
    object: users_by_pk(id: $id) {
      active
      id
      name
      email
      created_at
      photo_url
      currency
      language
      photo_url
      phone_number
      otp_verified
      email_verified
      buyer_access
      grower_access
      provider_access
      language
      location_id
      account_verified
      location {
        lat
        lng
        active
        id
        city
        country
      }
      locations {
        id
      }
    }
  }
`;

export const GET_BIDDERS = gql`
  subscription GET_BIDDERS($user_id: String!, $offer_id: uuid) {
    objects: requests(
      where: {
        request_id: { _eq: $offer_id }
        request: { user_id: { _eq: $user_id } }
        user_id: { _neq: $user_id }
      }
      distinct_on: user_id
    ) {
      id
      uid
      created_at
      updated_at
      active
      updated_at
      user_id
      user_id
      user {
        id
        email
        photo_url
        name
        photo_url
      }
    }
  }
`;

export const GET_PROVIDER_MATCHES = gql`
  subscription GET_PROVIDER_MATCHES($offer_id: uuid!, $user_id: String) {
    objects: provider_matches(
      where: { id: { _eq: $offer_id }, user_id: { _eq: $user_id } }
    ) {
      id
      range_in_kms
      distance
      user: matching_user {
        name
        active
        id
        photo_url
      }
      location: matching_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
        }
      }
    }
  }
`;

export const GET_TRADE_OFFER_BY_ID = gql`
  subscription GET_TRADE_OFFER_BY_ID($id: uuid!) {
    object: requests_by_pk(id: $id) {
      active
      id
      product_type_id
      uid
      negotiable
      commission_rate
      created_at
      updated_at
      type
      days_to_maturity
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      reviews_aggregate {
        aggregate {
          count
          avg {
            rating
          }
        }
      }
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      location_id
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      status

      type
      days_to_maturity

      unit

      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      total_amount
      advance_payment_percentage
      advance_amount
      from_date
      to_date
      logistics
      pictures
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
      matching_offers(order_by: { uid: desc }) {
        active
        id
        updated_at
        timestamp
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        type
        days_to_maturity
        reviews_aggregate {
          aggregate {
            count
            avg {
              rating
            }
          }
        }

        status

        pictures
        advance_amount
        total_amount
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        unit

        total_amount
        from_date
        to_date
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        logistics
        advance_payment_percentage
        active
        id
        uid
        created_at
        updated_at

        user_id
        user {
          active
          id
          email
          photo_url
          name
          photo_url
        }
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
      similar_offers_by_type_product_type(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        reviews_aggregate {
          aggregate {
            count
            avg {
              rating
            }
          }
        }
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        status

        unit

        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        from_date
        to_date
        pictures
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
      similar_offers_by_type_user(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        reviews_aggregate {
          aggregate {
            count
            avg {
              rating
            }
          }
        }
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        status

        unit

        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        from_date
        to_date
        pictures
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
    }
  }
`;

export const GET_TRADE_ORDER_BY_ID = gql`
  subscription GET_TRADE_ORDER_BY_ID($id: uuid!) {
    object: trade_orders_by_pk(id: $id) {
      id
      uid
      logistics_info
      created_at
      updated_at
      open_at
      completed_at
      cancelled_at
      advancepaid_at
      delivered_at
      fullypaid_at
      readyfordelivery_at
      received_at
      product_type_id
      product_type {
        id
        uid
        name
        product_id
        pictures
        product {
          id
          uid
          pictures
          name
        }
      }
      status
      dates
      price_per_unit
      number_of_units
      total_amount
      from_date
      active
      to_date
      advance_amount
      advance_payment_percentage
      status
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics(order_by: { created_at: desc }) {
        id
        uid
        active
        created_at
        updated_at
        contact_name
        contact_phone
        delivery_date
        pickup_date
        tracking_id
        status
        user_id
        user {
          id
          name
        }
      }
      logistics_aggregate {
        aggregate {
          count
        }
      }
      notes {
        created_at
        updated_at
        id
        text
        created_at
        user_id
        user {
          email
          photo_url
          name
          id
        }
      }
      seller {
        email
        photo_url
        name
        id
        phone_number
      }
      buyer {
        email
        photo_url
        name
        id
        phone_number
      }
      payments(order_by: { created_at: desc }) {
        created_at
        updated_at
        advance
        amount
        status

        updated_at
        timestamp
        id
        uid
        created_at
        stripe_session_id
      }
      unit

      offer: request {
        active
        id
        updated_at
        timestamp
        pictures
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        type
        days_to_maturity
        reviews_aggregate {
          aggregate {
            count
            avg {
              rating
            }
          }
        }
        status

        advance_amount
        total_amount
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        unit

        total_amount
        from_date
        to_date
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        logistics
        advance_payment_percentage
        active
        id
        uid
        created_at
        updated_at
        notes {
          created_at
          updated_at
          active
          id
          text
        }
        user_id
        user {
          active
          id
          email
          photo_url
          name
          photo_url
        }
        request_id
        request {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
      }
    }
  }
`;

export const GET_SERVICE_OFFER_BY_ID = gql`
  subscription GET_SERVICE_OFFER_BY_ID($id: uuid!) {
    object: provider_services_by_pk(id: $id) {
      active
      id
      uid
      status
      service_type_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      location_id
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      service_type {
        pictures
        active
        id
        name
        service_id
        service {
          pictures
          active
          id
          name
        }
      }
      range_in_kms
      price_per_unit
      pictures
      similar_services_by_service_type(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        location_id
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        service_type {
          pictures
          active
          id
          name
          service_id
          service {
            pictures
            active
            id
            name
          }
        }
        range_in_kms
        price_per_unit
        pictures
      }
      similar_services_by_user(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        reviews_aggregate {
          aggregate {
            count
            avg {
              rating
            }
          }
        }
        location_id
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        service_type {
          pictures
          active
          id
          name
          service_id
          service {
            pictures
            active
            id
            name
          }
        }
        range_in_kms
        price_per_unit
        pictures
      }
    }
  }
`;

export const GET_SERVICE_ORDER_BY_ID = gql`
  subscription GET_SERVICE_ORDER_BY_ID($id: uuid!) {
    object: service_orders_by_pk(id: $id) {
      id
      uid
      created_at
      updated_at
      open_at
      inprogress_at
      completed_at
      cancelled_at
      fullypaid_at
      serviced_at
      days_to_maturity
      total_amount
      price_per_unit
      number_of_units
      service_type_id
      service_type {
        pictures
        pictures
        id
        uid
        name
        service_id
        pictures
        service {
          id
          uid
          pictures
          name
        }
      }
      offer: provider_service {
        active
        id
        uid
        status

        user_id
        user {
          name
          active
          id
          photo_url
        }
        location_id
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          country
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        service_type {
          pictures
          active
          id
          name
          service_id
          service {
            pictures
            active
            id
            name
          }
        }
        range_in_kms
        price_per_unit
        pictures
      }
      status

      active
      date
      status
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        country
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      notes {
        created_at
        updated_at
        id
        text
        user_id
        user {
          email
          photo_url
          name
          id
        }
      }
      seller {
        email
        photo_url
        name
        id
        phone_number
      }
      buyer {
        email
        photo_url
        name
        id
        phone_number
      }
      payments(order_by: { created_at: desc }) {
        created_at
        updated_at
        advance
        amount
        status

        updated_at
        timestamp
        id
        uid
        created_at
        stripe_session_id
      }
    }
  }
`;

export const GET_FARM_BY_ID = gql`
  subscription GET_FARM_BY_ID($id: uuid!) {
    farm: locations_by_pk(id: $id) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      country
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  subscription GET_LOCATION_BY_ID($id: uuid!) {
    object: locations_by_pk(id: $id) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      country
    }
  }
`;

export const MATCHING_CONNECTIONS_COUNT = gql`
  subscription MATCHING_CONNECTIONS_COUNT($user_id: String!) {
    matching: users_by_pk(id: $user_id) {
      id
      count: matching_offer_counts_by_product_type_date
    }
  }
`;
export const GET_COMMISSIONS = gql`
  subscription GET_COMMISSIONS {
    commissions(where: { active: { _eq: true } }) {
      id
      active
      commission_rate
    }
  }
`;
