import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { Nav, Navbar } from "react-bootstrap";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { PATHS } from "../../config";
import { useLocalStorage } from "../../../../shared/helpers";

export function WeatherAlertHeader() {
  const location = useLocation();
  const { t } = useTranslatedLabel();
  const [, setPath] = useLocalStorage("path", window.location.pathname);
  const [isDashboard, setIsDashboard] = useState(false);

  useEffect(() => {
    setIsDashboard(location.pathname === "/dashboard");
  }, [location]);

  return (
    <>
      {!isDashboard && (
        <div className="row col m-0 p-0 " style={{ color: "#fff", background: "#f64e60" }}>
          <div className="container">
            <Navbar className="m-0 p-0">
              <Nav className="mr-auto"></Nav>
              <Nav.Item className="m-0 p-0 ml-2">
                {t("extreme_weather_alert_in_your_location")} :{" "}
                <Link to={PATHS.dashboard} className="text-white text-underline" onClick={() => setPath(`${PATHS.dashboard}`)}>
                  Go to Dashboard
                </Link>
              </Nav.Item>
              <Nav className="ml-auto"></Nav>
            </Navbar>
          </div>
        </div>
      )}
    </>
  );
}
