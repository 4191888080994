/* eslint-disable no-undef */
import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";
import reactCSS from "reactcss";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { weathericons } from "../../config/weatherapi";
import { UPDATE_LOCATION, WEATHER_FORECAST } from "../../gql";
import { GeneralContext } from "../../store";
import { LocationWidget } from "../location-widget";
import { WeatherAlertWidget } from "./weather-alert-widget";

// const dummyAlertsResponse = [
//   {
//     headline: "Flood Warning issued January 05 at 9:47PM EST until January 07 at 6:15AM EST by NWS",
//     msgtype: "Alert",
//     severity: "Moderate",
//     urgency: "Expected",
//     areas: "Calhoun; Lexington; Richland",
//     category: "Met",
//     certainty: "Likely",
//     event: "Flood Warning",
//     note: "Alert for Calhoun; Lexington; Richland (South Carolina) Issued by the National Weather Service",
//     effective: "2021-01-05T21:47:00-05:00",
//     expires: "2021-01-07T06:15:00-05:00",
//     desc: "...The Flood Warning continues for the following rivers in South\nCarolina...\nCongaree River At Carolina Eastman affecting Richland, Calhoun\nand Lexington Counties.\nCongaree River At Congaree National Park-Gadsden affecting\nCalhoun and Richland Counties.\nNorth Fork Edisto River At Orangeburg affecting Orangeburg County.\n...The Flood Warning is now in effect until Thursday morning...\nThe Flood Warning continues for\nthe Congaree River At Carolina Eastman.\n* Until Thursday morning.\n* At 9:28 PM EST Tuesday the stage was 115.6 feet.\n* Flood stage is 115.0 feet.\n* Minor flooding is occurring and minor flooding is forecast.\n* Recent Activity...The maximum river stage in the 24 hours ending\nat 9:28 PM EST Tuesday was 118.2 feet.\n* Forecast...The river will rise to 115.7 feet just after midnight\ntonight. It will then fall below flood stage tomorrow morning to\n114.2 feet and begin rising again tomorrow evening. It will rise\nto 114.3 feet early Thursday morning. It will then fall again and\nremain below flood stage.\n* Impact...At 115.0 feet, Flooding occurs in low lying areas of the\nCarolina Eastman Facility and at the Congaree National Park.\n* Flood History...This crest compares to a previous crest of 116.3\nfeet on 12/03/2020.\n&&",
//     instruction:
//       "A Flood Warning means that flooding is imminent or occurring. All\ninterested parties should take necessary precautions immediately.\nMotorists should not attempt to drive around barricades or drive\ncars through flooded areas.\nCaution is urged when walking near riverbanks.\nAdditional information is available at www.weather.gov.\nThe next statement will be issued Wednesday morning at 1000 AM EST.",
//   },
// ];

const WeatherCard = (props) => {
  const { general_product_types, general_certifications } = useContext(GeneralContext);
  const { color, lat, lng, name, id, city, is_verified, crops, certifications } = props;
  const { t } = useTranslatedLabel();
  const [weatherdata, setWeatherData] = useState({});
  const [weatherAlerts, setWeatherAlerts] = useState([]);
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const [location_data, setLocationData] = useState({ crops: [], certifications: [] });
  const [current_color, setCurrentColor] = useState(null);
  const [color_picker, setColorPicker] = useState(false);

  const styles = reactCSS({
    default: {
      color: {
        width: "20px",
        height: "20px",
        borderRadius: "2px",
        background: current_color || color,
      },
      swatch: {
        padding: "2px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const { loading, error, data } = useQuery(WEATHER_FORECAST, {
    variables: { lat: lat, lng: lng },
    pollInterval: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setWeatherData(data?.weather_forecast?.current);
      setWeatherAlerts(data?.weather_forecast?.alerts);
    }
  }, [loading, error, data]);

  useEffect(() => {
    const cropsData = general_product_types.filter((e) => crops?.includes(e.id));
    const certificationsData = general_certifications.filter((e) => certifications?.includes(e.id));
    setLocationData({ crops: cropsData, certifications: certificationsData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crops, certifications]);

  return (
    <>
      {weatherAlerts.length > 0 && (
        <div className="text-center text-danger bg-white">
          {t("extreme_weather")} :
          <WeatherAlertWidget weatherAlerts={weatherAlerts} />
        </div>
      )}
      {weatherdata && (
        <div
          className={`row ${is_verified ? "" : "border border-danger border-3"} ${
            weatherAlerts.length > 0 ? "bg-danger" : "bg-white"
          }`}
        >
          <div className="col-12 p-2">
            <div className="d-flex justify-content-between align-items-center">
              <img src={weathericons[weatherdata?.condition?.code - 887]} alt="" className="location-weather-icon" />

              <div style={{ textAlign: "center" }}>
                <small className="mb-0">{weatherdata?.condition?.text}</small>
                <p className="m-0">
                  <span className="location-weather-temp">{weatherdata?.temp_c} °C</span>
                </p>
              </div>

              <small
                style={{
                  borderLeft: "1px dotted #dddddd",
                  paddingLeft: "6px",
                }}
              >
                {t("wind")}: {weatherdata?.wind_kph} kmph
                <br />
                {t("precipitation")}: {weatherdata?.precip_mm} mm
                <br />
                {t("pressure")}: {weatherdata?.pressure_mb} mb
                <br />
                {t("humidity")}: {weatherdata?.humidity}
              </small>
            </div>
          </div>

          <div className="col-1">
            <div style={styles.swatch} onClick={() => setColorPicker(true)}>
              <div style={styles.color} />
            </div>
            {color_picker && (
              <div style={styles.popover}>
                <div style={styles.cover} onClick={() => setColorPicker(false)} />
                <SketchPicker
                  color={current_color ? current_color : color}
                  onChange={(e) => {
                    setCurrentColor(e.hex);
                    updateLocation({ variables: { id: id, object: { color: e.hex } } });
                  }}
                />
              </div>
            )}
          </div>
          <div className="col text-left">
            <Link to={`/locations/${id}`}>
              <b>{name}</b>
            </Link>
            <small>
              {" "}
              ( {city} ) {!is_verified && t("not_verified")}
            </small>
            <LocationWidget key={id} data={{ ...props, ...location_data }} icon={true} />
          </div>
        </div>
      )}
    </>
  );
};

export default WeatherCard;
